import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { HomePage } from 'components/_v2/HomePage';
import Seminars from 'components/Seminars-Page/Listing-Page/Seminars';
import Workshops from 'components/Workshop-Page/Listing-Page/Workshops';
import Course from 'components/Courses-Page/Listing-Page/Course';
import { CourseDetail } from 'components/Courses-Page/Detail-Page/CourseDetail';
import MentorProfile from 'components/Home-Page/mentors/profile-page/MentorProfile';
import { Cart } from 'components/Cart/Cart';
import { Payment } from 'components/Cart/Checkout/Payment';
import Webinar from 'components/Webinar-Page/Listing-Page/WebinarNew';
import CourseCompleted from 'components/PreRecordedCourses/CourseCompleted';
import WebinarWillGoLive from 'components/LiveWebinar/WebinarWillGoLive';
import WebinarIsLive from 'components/LiveWebinar/WebinarIsLive';
import WebinarCompleted from 'components/LiveWebinar/WebinarCompleted';
import CourseWillGoLive from 'components/LiveCourse/CourseWillGoLive';
import CourseIsLive from 'components/LiveCourse/CourseIsLive';
import { MyProfile } from 'components/Profile-page/MyProfile';
import { Cms } from 'components/Cms-Page/Cms';
import OrderPaymentDetails from 'components/order-Payment/OrderPaymentDetails';
import PersonalDetailsEdit from 'components/Profile-page/Profile/PersonalDetailsEdit';
import MyinterestEdit from 'components/Profile-page/Profile/MyinterestEdit';
import LiveCourseCompleted from 'components/LiveCourse/LiveCourseCompleted';
import { MentorsList } from 'components/Home-Page/mentors/MentorsList';
import { Help } from 'components/Cms-Page/Help';
import { Gst } from 'components/Cart/Checkout/Gst';
import Learning from 'components/Profile-page/MyLearnings/Learning';
import Forgetpassword from '../auth/Forgetpassword';
import EmailVerification from 'components/emailverification/emailverification';
import ThankYou from 'components/Thank-you/ThankYou';
import Quiz2 from 'components/Coursequiz/Quiz2';
import QuizSummary from 'components/Coursequiz/QuizSummary';
import QuizSidebar from 'components/Coursequiz/QuizSidebar';
import QuizStart from 'components/Coursequiz/QuizStart';
import Bookmark from 'components/Bookmark/Bookmark';
import YourLearning from 'components/Home-Page/home/YourLearning';
import YourUpcoming from 'components/Home-Page/home/YourUpcoming';
import { RootLayout } from '../layout/RootLayout';
import MyCourses from 'components/MyCourses/MyCourses';
import CourseDetails from 'components/MyCourses/CourseDetails';
import {
	ABOUT_US,
	BASE_PAGE_URL,
	CHANGE_PASSWORD_URL,
	CONTACT_US,
	COURSE_URL,
	DASHBOARD_URL,
	DETAIL_URL,
	ENROLL_URL,
	HOME_URL,
	INTERNAL_SERVER_ERROR,
	MY_COURSE_URL,
	MY_PROFILE_URL,
	NOTIFICATIONS_LIST,
	PAYMENT_DETAILS_NO_USER,
	PAYMENT_HISTORY_URL,
	PAYMENT_STATUS_URL,
	PERSONAL_DETAIL_URL,
	PRIVACY_POLICY_URL,
	TNC_URL,
	VIEW_COURSE_DATA_URL,
	ZOOM_MEETING,
} from './routePages';
import PNP from 'components/_v2/Cms_New/PNP';
import TNC from 'components/_v2/Cms_New/TNC';
import AllCourse from 'components/_v2/AllCourse/AllCourse';
import DetailPage from 'components/_v2/CourseDetail/DetailHeroSection/DetailPage';
import PaymentDetails from 'components/_v2/PaymentDetails/PaymentDetails';
import PaymentStatus from 'components/_v2/PaymentStatus/PaymentStatus';
import Home from 'components/_v2/Home/Home';
import MyCourse from 'components/_v2/MyCourse/MyCourse';
import { useUserContext } from 'context/UserContext';
import ViewCourseData from 'components/_v2/ViewCourseData/ViewCourseData';
import { useCourseContext } from 'context/CourseContext';
import useCourses from 'hooks/useCourses';
import PaymentHistory from 'components/_v2/PaymentHistory/PaymentHistory';
import MYProfile from 'components/_v2/MyProfile/MYProfile';
import PersonalDetail from 'components/_v2/MyProfile/PersonalDetail';
import ChangePassword from 'components/_v2/MyProfile/ChangePassword';
import NotFound from 'components/_v2/NotFound/NotFound';
import NotificationList from 'components/_v2/Header/NotificationList';
import ZoomMeeting from 'components/_v2/ViewCourseData/LessonTypesComponents/ZoomMeeting';
import Loading from 'components/_v2/CommonComponents/Loading';
import ContactUs from 'components/_v2/ContactUs/ContactUs';
import AboutUs from 'components/_v2/AboutUs/AboutUs';
import InternalServerError from 'components/_v2/CommonComponents/InternalServerError';
import PaymentDetailsNonLoggedUser from 'components/_v2/PaymentDetails/PaymentDetailsNonLoggedUser';
import { useLazyQuery } from '@apollo/client';
import { GetCountriesWithCode } from 'types/globalTypes';
import { GET_COUNTRIES_WITH_CODE } from 'components/Profile-page/core/requests';
import { useUserAddressContext } from 'context/UserAddressContext';
import { CountryObjectType } from 'generated/types';

export const PrivateRoutes = () => {
	const { localStorageAuthToken, user } = useUserContext();
	const { hasValueSet } = useCourseContext();
	const { getCourses, loadingPublicCourses, loadingStudentCourses, getUserCourses } = useCourses();
	const {
		setCountries,
		setStates,
		setSelectedCountryId,
		setSelectedStateId,
		setAllCountryData,
		setSelectedCityId,
		setCities,
	} = useUserAddressContext();

	useEffect(() => {
		if (!hasValueSet) {
			getCourses();
		}
	}, [hasValueSet]);

	const navigate = useNavigate();
	useEffect(() => {
		if (!localStorageAuthToken && window.location.pathname.includes('/my-profile')) {
			navigate('/');
		} else {
			getUserCourses();
		}
	}, [localStorageAuthToken]);

	const [getCountriesData] = useLazyQuery<GetCountriesWithCode>(GET_COUNTRIES_WITH_CODE, {
		onCompleted: (data) => {
			const allCountriesData = data.getCountriesWithCode;
			setAllCountryData(allCountriesData);
			const countries: { value: string; label: string }[] = allCountriesData.reduce(
				(accumulator, previousValue: CountryObjectType) => {
					const countriesObject = {
						label: previousValue.countryName,
						value: previousValue.id,
					};
					accumulator.push(countriesObject);
					return accumulator;
				},
				[],
			);
			setCountries(countries);
			const userCountryStates = (
				allCountriesData.find((country) =>
					country.countryName
						.toLowerCase()
						.includes(user?.userProfile?.[0]?.country?.countryName.toLowerCase()),
				) ?? allCountriesData[0]
			)?.states;
			setStates(() =>
				userCountryStates.map((state) => {
					return {
						value: state.id,
						label: state.stateName,
					};
				}),
			);
			const selectedCountryId =
				(
					data.getCountriesWithCode?.find((country) =>
						country.id.toLowerCase().includes(user?.userProfile?.[0]?.countryId?.toLowerCase()),
					) ?? data.getCountriesWithCode[0]
				)?.id ?? '';
			setSelectedCountryId(selectedCountryId);
			const countryStates =
				data.getCountriesWithCode?.find((country) => country.id === selectedCountryId)?.states ??
				[];
			const selectedStateId =
				(
					countryStates?.find((state) =>
						state?.id?.toLowerCase()?.includes(user?.userAddress?.[0]?.stateId?.toLowerCase()),
					) ?? countryStates[0]
				)?.id ?? '';
			setSelectedStateId(selectedStateId);
			const citiesData = countryStates?.find((state) => state?.id === selectedStateId)?.cities;
			const citiesMappedData = citiesData.map((city) => ({
				label: city.cityName,
				value: city.id,
			}));
			setCities(citiesMappedData);
			const selectedCityId =
				(
					citiesMappedData?.find((state) =>
						state?.value?.toLowerCase()?.includes(user?.userAddress?.[0]?.cityId?.toLowerCase()),
					) ?? citiesMappedData[0]
				)?.value ?? '';
			setSelectedCityId(selectedCityId);
		},
	});

	useEffect(() => {
		getCountriesData();
	}, [user]);

	return (
		<div>
			{loadingPublicCourses || (loadingStudentCourses && <Loading height="100vh" width={250} />)}
			<div className={loadingPublicCourses || loadingStudentCourses ? 'd-none' : ''}>
				<Routes>
					<Route element={<RootLayout />}>
						{/* Main Layout Routes */}
						<Route path={BASE_PAGE_URL} element={<HomePage />} />
						{/* Dashboard Layout Routes */}
						<Route path={DASHBOARD_URL} element={<Home />} />
						<Route path={PAYMENT_HISTORY_URL} element={<PaymentHistory />} />
						<Route path={MY_PROFILE_URL} element={<MYProfile />} />
						<Route path={PRIVACY_POLICY_URL} element={<PNP />} />
						<Route path={TNC_URL} element={<TNC />} />
						<Route path={CONTACT_US} element={<ContactUs />} />
						<Route path={ABOUT_US} element={<AboutUs />} />
						<Route path={COURSE_URL} element={<AllCourse />} />
						<Route path={`${DETAIL_URL}/:courseSlug`} element={<DetailPage />} />
						<Route path={`${ENROLL_URL}/:courseSlug`} element={<PaymentDetails />} />
						<Route
							path={`${PAYMENT_DETAILS_NO_USER}/:courseSlug`}
							element={<PaymentDetailsNonLoggedUser />}
						/>
						<Route path={PAYMENT_STATUS_URL} element={<PaymentStatus />} />
						<Route path={MY_COURSE_URL} element={<MyCourse />} />
						<Route path={PERSONAL_DETAIL_URL} element={<PersonalDetail />} />
						<Route path={CHANGE_PASSWORD_URL} element={<ChangePassword />} />
						<Route path={VIEW_COURSE_DATA_URL} element={<ViewCourseData />} />
						<Route path={NOTIFICATIONS_LIST} element={<NotificationList />} />
						<Route path={ZOOM_MEETING} element={<ZoomMeeting />} />
						<Route path={INTERNAL_SERVER_ERROR} element={<InternalServerError />} />
						{/* ------------------------------------------------OLD COMPONENTS BELOW--------------------------------- */}
						<Route path="/dashboard/my-courses" element={<MyCourses />} />
						<Route path="/dashboard/course-detail/:slug" element={<CourseDetails />} />
						<Route path="/home" element={<HomePage />} />
						<Route path="/webinars" element={<Webinar />} />
						<Route path="/email-verification/:token" element={<EmailVerification />} />
						<Route path="/thank-you" element={<ThankYou />} />
						<Route path="/courses" element={<Course />} />
						<Route path="/course-view/:courseslug/*" element={<CourseDetail />} />
						<Route path="mentor-view/:mentorId/*" element={<MentorProfile />} />
						<Route path="/mentors-list" element={<MentorsList />} />
						<Route path="/seminars" element={<Seminars />} />
						<Route path="/workshops" element={<Workshops />} />
						<Route
							path="/checkout"
							element={localStorageAuthToken ? <Cart /> : <Navigate to="/" />}
						/>
						<Route path="/checkout/payment" element={<Payment />} />
						<Route path="/my-profile" element={localStorageAuthToken && <MyProfile />} />
						<Route path="/page/:pageSlug/*" element={<Cms />} />
						<Route path="/yourlearning" element={<YourLearning />} />
						<Route path="/mylearning" element={<Learning />} />
						<Route path="/yup" element={<YourUpcoming />} />
						<Route path="/editmyinterests" element={<MyinterestEdit />} />
						<Route path="/editpersonaldetails" element={<PersonalDetailsEdit />} />
						<Route path="/quizsummary" element={<QuizSummary />} />
						<Route path="/quizsidebar" element={<QuizSidebar />} />
						<Route path="/quizstart" element={<QuizStart />} />
						<Route path="/quiz2" element={<Quiz2 />} />
						<Route path="/help" element={<Help />} />
						<Route path="/gst" element={<Gst />} />
						<Route path="/forgetpassword/:token" element={<Forgetpassword />} />
						<Route path="/bookmark" element={<Bookmark />} />
						{/* <Route
              path="/coursecontentvideo/:courseslug"
              element={<CourseContentVideo />}
            /> */}
						<Route path="/coursecompleted/:courseslug" element={<CourseCompleted />} />
						<Route path="/webinarwillgolive" element={<WebinarWillGoLive />} />
						<Route path="/webinarislive/:courseslug" element={<WebinarIsLive />} />
						<Route path="/webinarcompleted/:courseslug" element={<WebinarCompleted />} />
						<Route path="/coursewillgolive" element={<CourseWillGoLive />} />
						<Route path="/courseislive" element={<CourseIsLive />} />
						<Route path="/livecoursecompleted" element={<LiveCourseCompleted />} />
						<Route path="/orderpaymentdetails" element={<OrderPaymentDetails />} />
					</Route>
					<Route path="*" element={<NotFound />} />
					<Route path="/order-status-offline" element={<PaymentStatus />} />
				</Routes>
			</div>
		</div>
	);
};
