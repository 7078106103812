import React from 'react';
import './signupbutton.css';
import Button from 'components/_v2/CommonComponents/Button';
import { ButtonBorderType, ButtonType } from 'components/_v2/CommonComponents/types';
import { useAuthModalContext } from 'context/AuthContext';

const SignupButton = () => {
const { toggleRegisterModal } = useAuthModalContext();

	return (
		<>
			<Button
				buttonText="Sign Up"
				buttonType={ButtonType.PRIMARY_BUTTON}
				handleClick={toggleRegisterModal}
				classes="g_mid"
				buttonBorderType={ButtonBorderType.BLACK_BORDER}
			/>
		</>
	);
};

export default SignupButton;
