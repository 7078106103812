const removeChatStickyFromUi = () => {
	const rocketChatInstance = document.querySelector('.rocketchat-widget');
	if (rocketChatInstance) {
		const styleAttributes = rocketChatInstance.getAttribute('style');
		const newStyleAttributes = styleAttributes + 'display: none;';
		rocketChatInstance.setAttribute('style', newStyleAttributes);
	}
};

const reShowChatStickyOnUi = () => {
	const rocketChatInstance = document.querySelector('.rocketchat-widget');
	if (rocketChatInstance) {
		const styleAttributes = rocketChatInstance.getAttribute('style');
		const newStyleAttributes = styleAttributes.replace('display: none;', 'display: block;');
		rocketChatInstance.setAttribute('style', newStyleAttributes);
	}
};

export { removeChatStickyFromUi, reShowChatStickyOnUi };
