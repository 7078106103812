import React, { useRef, useState } from 'react';
import ReactCrop, { centerCrop, Crop, makeAspectCrop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { useDebounceEffect } from 'hooks/useDebounceEffect';
import { canvasPreview } from 'components/_v2/CommonComponents/ImageCrop/canvasPreview';
import Button from 'components/_v2/CommonComponents/Button';
import { ButtonBorderType, ButtonType } from 'components/_v2/CommonComponents/types';
import { toast } from 'react-hot-toast';
import Loading from 'components/_v2/CommonComponents/Loading';

// This is to demonstrate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
	return centerCrop(
		makeAspectCrop(
			{
				unit: '%',
				width: 90,
			},
			aspect,
			mediaWidth,
			mediaHeight,
		),
		mediaWidth,
		mediaHeight,
	);
}

const ImageCropHelper = ({
	imgSrc,
	aspect = 16 / 9,
	onComplete,
	circularCrop = false,
}: {
	imgSrc: string;
	aspect?: number;
	circularCrop?: boolean;
	onComplete: (imageAfterCrop: string | null) => void;
}) => {
	const previewCanvasRef = useRef<HTMLCanvasElement>(null);
	const imgRef = useRef<HTMLImageElement>(null);
	const [crop, setCrop] = useState<Crop>();
	const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
	const [scale, setScale] = useState(1);
	const [convertingImageToBlob, setConvertingImageToBlob] = useState(false);

	function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
		if (aspect) {
			const { width, height } = e.currentTarget;
			setCrop(centerAspectCrop(width, height, aspect));
		}
	}

	useDebounceEffect(
		async () => {
			if (
				completedCrop?.width &&
				completedCrop?.height &&
				imgRef.current &&
				previewCanvasRef.current
			) {
				// We use canvasPreview as it's much faster than imgPreview.
				await canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale);
			}
		},
		100,
		[completedCrop, scale],
	);

	const convertCanvasToBlob = (canvas: HTMLCanvasElement): Promise<Blob> => {
		return new Promise((resolve, reject) => {
			canvas.toBlob((blob) => {
				if (blob) {
					resolve(blob);
				} else {
					reject(new Error('Failed to convert canvas to Blob'));
				}
			}, 'image/png'); // Specify the format
		});
	};

	const blobToDataUrl = (blob: Blob): Promise<string> => {
		return new Promise((resolve) => {
			const reader = new FileReader();
			reader.onloadend = () => resolve(reader.result as string);
			reader.readAsDataURL(blob);
		});
	};

	const handleFinaliseCrop = async () => {
		let image = imgRef.current;
		const previewCanvas = previewCanvasRef.current;

		if (!image || !previewCanvas || !completedCrop) {
			throw new Error('Crop canvas does not exist');
		}
		try {
			setConvertingImageToBlob(true);
			const blob = await convertCanvasToBlob(previewCanvas);
			const dataUrl = await blobToDataUrl(blob);
			setConvertingImageToBlob(false);
			onComplete(dataUrl);
		} catch (error) {
			setConvertingImageToBlob(false);
			console.error('Error:', error);
			toast.error('There was some error in cropping image.');
		}
	};

	return (
		<div className="w-100 h-100">
			<div className="p-3">
				<div className="Crop-Controls">
					<div>
						<label htmlFor="scale-input">Scale: </label>
						<input
							id="scale-input"
							type="number"
							step="0.1"
							value={scale}
							disabled={!imgSrc}
							onChange={(e) => setScale(Number(e.target.value))}
						/>
					</div>
				</div>
				<div className="row p-5">
					{!!imgSrc && (
						<div className="col-12 col-lg-6 align-self-center">
							<div className="d-flex w-100 h-100 flex-column justify-content-center align-items-center">
								<ReactCrop
									crop={crop}
									circularCrop={circularCrop}
									onChange={(_, percentCrop) => setCrop(percentCrop)}
									onComplete={(c) => setCompletedCrop(c)}
									aspect={aspect}
									minHeight={100}>
									<img
										ref={imgRef}
										alt="Crop me"
										src={imgSrc}
										style={{ transform: `scale(${scale})` }}
										onLoad={onImageLoad}
									/>
								</ReactCrop>
							</div>
						</div>
					)}
					{!!completedCrop && (
						<div className="col-12 col-lg-6 align-self-center">
							<div className="d-flex w-100 h-100 flex-column justify-content-center align-items-center">
								<p className="g_mid mt-lg-0 mt-4">Preview</p>
								<canvas
									ref={previewCanvasRef}
									style={{
										border: '1px solid black',
										objectFit: 'contain',
										width: completedCrop.width,
										height: completedCrop.height,
										borderRadius: circularCrop ? '50%' : '0%',
									}}
								/>
							</div>
						</div>
					)}
				</div>
				<Button
					buttonText={
						<span className="d-flex w-100 justify-content-center gap-2">
							{convertingImageToBlob && <Loading width={50} />}
							Finalise Crop
						</span>
					}
					classes="g_mid"
					disabled={convertingImageToBlob}
					buttonType={ButtonType.PRIMARY_BUTTON}
					handleClick={handleFinaliseCrop}
					buttonBorderType={ButtonBorderType.BLACK_BORDER}
				/>
			</div>
		</div>
	);
};

export default ImageCropHelper;
