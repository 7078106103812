import React, { useEffect, useMemo, useState } from 'react';
import './PaymentDetails.css';
import { BASE_PAGE_URL, MY_COURSE_URL, PAYMENT_DETAILS_NO_USER } from 'pages/routing/routePages';
import { useNavigate, useParams } from 'react-router-dom';
import { useCourseContext } from 'context/CourseContext';
import { Course, CourseCostType } from '../Course/type';
import { RUPEE_SYMBOL } from 'utils/consts';
import useCourses from 'hooks/useCourses';
import { useMutation } from '@apollo/client';
import { CREATE_ORDER_MUTATION, ENROLL_FREE_COURSE } from 'components/Cart/core/requests';
import toast from 'react-hot-toast';
import { useUserContext } from 'context/UserContext';
import { CreateOrderDetailsInput } from 'types/globalTypes';
import Loading from 'components/_v2/CommonComponents/Loading';
import Button from '../CommonComponents/Button';
import { ButtonBorderType, ButtonType } from '../CommonComponents/types';
import CourseMoneyBreakdown from 'components/_v2/PaymentDetails/CourseMoneyBreakdown';
import CoursePaymentHeaderWithLogo from 'components/_v2/PaymentDetails/CoursePaymentHeaderWithLogo';
import { useUserAddressContext } from 'context/UserAddressContext';
import { removeChatStickyFromUi, reShowChatStickyOnUi } from 'utils/helpers';

const PaymentDetails = () => {
	const navigate = useNavigate();
	const { user } = useUserContext();
	const { courseSlug } = useParams();
	const { hasValueSet } = useCourseContext();
	const { getCourses, courses } = useCourses();
	const { selectedCountryId, selectedStateId } = useUserAddressContext();
	const [createOrder, { loading }] = useMutation(CREATE_ORDER_MUTATION);
	const [enrollFreeCourse, { loading: loadingEnrollFreeCourse }] = useMutation(ENROLL_FREE_COURSE);
	const [loadingNewUrl, setLoadingNewUrl] = useState(false);

	useEffect(() => {
		if (!hasValueSet) {
			getCourses();
		}
	}, [hasValueSet]);

	useEffect(() => {
		if (!user) {
			navigate(`${PAYMENT_DETAILS_NO_USER}/${courseSlug}`);
		}
		removeChatStickyFromUi();
		return () => {
			reShowChatStickyOnUi();
		};
	}, []);

	const selectedCourse = useMemo(() => {
		if (courses.length) {
			const course = courses.find((course: Course) => course.slug === courseSlug);
			if (!course) {
				navigate(BASE_PAGE_URL);
			}
			return course;
		}
	}, [courses]);

	const totalDiscount = useMemo(
		() => (selectedCourse?.amount ?? 0) - (selectedCourse?.sellPrice ?? 0),
		[selectedCourse],
	);

	const gstOverSellPrice = useMemo(() => {
		const gst = ((selectedCourse?.sellPrice ?? 0) / 100) * 18;
		return parseFloat(gst.toFixed(2));
	}, [selectedCourse]);

	const totalPayable = useMemo(
		() => gstOverSellPrice + (selectedCourse?.sellPrice ?? 0),
		[selectedCourse],
	);

	const handleSuccessPageReroute = () => {
		if (selectedCourse.costType === CourseCostType.FREE) {
			enrollFreeCourse({
				variables: {
					createFreeCourseEnrollmentInput: {
						courseId: selectedCourse.id,
						countryId: selectedCountryId,
						stateId: selectedStateId,
					},
				},
				onCompleted: () => {
					window.location.href = MY_COURSE_URL;
				},
			});
		} else {
			const cartItems: CreateOrderDetailsInput[] = [
				{
					courseId: selectedCourse.id,
					noOfSeat: 1,
					price: selectedCourse?.amount,
					totalPrice: totalPayable,
					subTotal: selectedCourse?.sellPrice ?? 0,
					tax: 18,
					taxAmount: gstOverSellPrice,
				},
			];
			createOrder({
				variables: {
					createOrderInputData: {
						fullName: user?.fullName,
						stateId: selectedStateId ?? user.userAddress?.[0]?.stateId,
						countryId: selectedCountryId ?? user.userProfile?.[0]?.countryId,
						cartitems: cartItems,
						gstNo: '',
					},
				},
				onCompleted: (data: any) => {
					toast.success('Successfully created Order', {
						style: {
							padding: '16px',
						},
					});
					setLoadingNewUrl(true);
					window.location.href = data.createOrder.short_url;
				},
				onError: (err: any) => {
					toast.error(err.message);
				},
			});
		}
	};

	return (
		<section className="payment-detail-wrapper">
			<div className="container">
				<div className="row justify-content-center">
					<div className="card-layout">
						<div className="card px-4 pb-3">
							<CoursePaymentHeaderWithLogo />
							<CourseMoneyBreakdown course={selectedCourse} />
							<div className="px-3">
								<Button
									buttonText={
										<span className="d-flex justify-content-center gap-2">
											{(loading || loadingNewUrl) && <Loading width={50} />}
											{selectedCourse.costType === CourseCostType.PAID ? (
												<span>
													Proceed to Pay{'  '}
													<span>
														{RUPEE_SYMBOL}
														{totalPayable.toFixed(2)}
													</span>
												</span>
											) : (
												<span>Proceed to Enroll</span>
											)}
										</span>
									}
									buttonType={ButtonType.PRIMARY_BUTTON}
									classes={`g_mid w-100 ${loading ? 'opacity-50' : ''}`}
									handleClick={handleSuccessPageReroute}
									buttonBorderType={ButtonBorderType.BLACK_BORDER}
									disabled={loading || loadingNewUrl}
								/>
							</div>
							<div className="text-center">
								<span className="payment-sub-desc g_reg">
									By proceeding you agree to our Terms, Privacy & Refund Policy
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default PaymentDetails;
