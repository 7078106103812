import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Course, UserCourseRating } from 'components/_v2/Course/type';
import CardInformation from 'components/_v2/CommonComponents/CardInformation';
import {
	ButtonBorderType,
	ButtonType,
	CardHeight,
	SliderComponentType,
} from 'components/_v2/CommonComponents/types';
import { UserType } from 'types/globalTypes';
import Slider, { Settings } from 'react-slick';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { Image } from 'react-bootstrap';
import './CommonSlider.scss';
import SliderProgressPagination from 'components/_v2/CommonComponents/SliderProgressPagination';
import Button from './Button';
import { reviewCardRenderer } from 'components/_v2/CommonComponents/helper';
import useIsSmallScreen from 'hooks/useIsSmallScreen';

const CommonSlider = ({
	headingText,
	headingSubText,
	courses,
	reviewsData,
	viewAllAction,
	disableHoverInteraction = false,
	userCourses,
	user,
	showBackdropShadow,
	slidesToShow = 3,
	infinite = false,
	hasTopRightButtons = false,
	sliderComponentType,
	showViewAllButton,
	fullWidth,
}: {
	headingText?: string;
	headingSubText?: string;
	courses?: Course[];
	reviewsData?: UserCourseRating[];
	viewAllAction?: () => void;
	disableHoverInteraction?: boolean;
	userCourses?: Course[];
	user?: UserType;
	showBackdropShadow: boolean;
	slidesToShow: number;
	infinite?: boolean;
	hasTopRightButtons?: boolean;
	sliderComponentType: SliderComponentType;
	showViewAllButton: boolean;
	fullWidth?: boolean;
}) => {
	const sliderRef = useRef<Slider | null>(null);
	const [currentSlide, setCurrentSlide] = useState(0);
	const [slidesToShowResponsive, setSlidesToShowResponsive] = useState(slidesToShow);
	const arrayToUse = sliderComponentType === SliderComponentType.COURSES ? courses : reviewsData;

	const individualProgressPercentage = (slidesToShowResponsive / arrayToUse.length) * 100;
	const startingOffset = (100 / arrayToUse.length) * currentSlide;

	const isSmallScreen = useIsSmallScreen(768);
	const isTabletScreen = useIsSmallScreen(1450);

	const ViewAllButton = useMemo(() => {
		return (
			<Button
				buttonText="View All &nbsp; >"
				buttonType={ButtonType.SECONDARY_BUTTON}
				classes="g_mid"
				handleClick={viewAllAction}
				buttonBorderType={ButtonBorderType.PRIMARY_BORDER}
			/>
		);
	}, []);

	useEffect(() => {
		// Function to update slidesToShow based on window width
		const handleResize = () => {
			const width = window.innerWidth;
			if (sliderComponentType === SliderComponentType.REVIEWS) {
				if (width < 768) {
					setSlidesToShowResponsive(1);
				} else {
					setSlidesToShowResponsive(2);
				}
			} else {
				if (width < 768) {
					setSlidesToShowResponsive(1);
				} else if (width < 1100) {
					setSlidesToShowResponsive(2);
				} else if (width < 1450) {
					setSlidesToShowResponsive(!fullWidth ? 3 : 2);
				} else {
					setSlidesToShowResponsive(!fullWidth ? 4 : 3);
				}
			}
		};

		handleResize();

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const showBottomSlider = useMemo(() => {
		if (sliderComponentType === SliderComponentType.REVIEWS && isTabletScreen) {
			return arrayToUse?.length != 1 && slidesToShowResponsive < 3;
		} else if (sliderComponentType === SliderComponentType.COURSES) {
			if (fullWidth) {
				return slidesToShowResponsive <= 2;
			}
			return slidesToShowResponsive < 3;
		} else return false;
	}, [slidesToShowResponsive, sliderComponentType, isTabletScreen]);

	const settings: Settings = {
		infinite,
		afterChange: (current) => setCurrentSlide(current),
		speed: 500,
		slidesToShow,
		responsive: [
			{
				breakpoint: 2500,
				settings: {
					slidesToShow: sliderComponentType === SliderComponentType.COURSES && !fullWidth ? 4 : 3,
				},
			},
			{
				breakpoint: 1450,
				settings: {
					slidesToShow: sliderComponentType === SliderComponentType.COURSES && !fullWidth ? 3 : 2,
				},
			},
			{
				breakpoint: 1100,
				settings: {
					slidesToShow: sliderComponentType === SliderComponentType.COURSES && !fullWidth ? 3 : 2,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 550,
				settings: {
					slidesToShow: 1,
				},
			},
		],
		slidesToScroll: 1,
		arrows: !showBottomSlider,
		dots: false,
		prevArrow:
			currentSlide === 0 || hasTopRightButtons ? (
				<></>
			) : (
				<button>
					<Image src={toAbsoluteUrl('/media/icons/previous.svg')} alt="Left_Slider" />
				</button>
			),
		nextArrow:
			currentSlide === arrayToUse.length - slidesToShowResponsive || hasTopRightButtons ? (
				<></>
			) : (
				<button>
					<Image src={toAbsoluteUrl('/media/icons/next.svg')} alt="Right_Slider" />
				</button>
			),
		centerPadding: '50px',
	};

	const handlePrev = () => {
		sliderRef.current?.slickPrev();
	};

	const handleNext = () => {
		sliderRef.current?.slickNext();
	};

	const SliderContent = ({ sliderComponentType }: { sliderComponentType: SliderComponentType }) => {
		switch (sliderComponentType) {
			case SliderComponentType.COURSES:
				return courses.map((course: Course) => (
					<CardInformation
						key={course.id}
						course={course}
						disableHoverInteraction={disableHoverInteraction}
						isUserSpecificContent={false}
						userCourses={userCourses}
						user={user}
						cardHeight={CardHeight.LARGE}
						showBackdropShadow={showBackdropShadow}
						isSliderCard
					/>
				));
			case SliderComponentType.REVIEWS:
				return (reviewsData || []).map((review, index) =>
					reviewCardRenderer({
						desc: review.review,
						key: index,
						ratings: review.starCount,
						name: review.user.fullName,
						isOnlySingleTestimonial: reviewsData.length === 1,
					}),
				);
		}
	};

	useEffect(() => {
		setTimeout(() => {
			const slickTracks = document.querySelectorAll('.commonSlider .slick-track');

			// Convert NodeList to an array or use forEach
			slickTracks.forEach((item) => {
				(item as HTMLElement).style.transform = 'translate3d(0, 0, 0)';
			});
		}, 300); // small delay to let slick initialize
	}, []);

	return (
		<div
			className={
				sliderComponentType === SliderComponentType.COURSES
					? 'w-100 d-flex justify-content-center px-lg-2 px-0'
					: 'pb-5'
			}>
			<div
				className={
					sliderComponentType === SliderComponentType.COURSES && !fullWidth
						? 'col-sm-11 col-xl-9'
						: 'col-md-12'
				}>
				<div
					className={`d-flex justify-content-between align-items-center ${
						isSmallScreen && sliderComponentType === SliderComponentType.COURSES ? 'px-4' : ''
					}`}>
					<div>
						{headingText && (
							<p
								className={`${
									sliderComponentType === SliderComponentType.COURSES ? 'ibm_semi' : 'ibm_mid'
								} color16`}>
								<span className="d-flex align-items-center">
									{headingText && (
										<span
											className={`ibm_semi ${
												sliderComponentType === SliderComponentType.COURSES ? 'fs-40' : 'fs-32'
											}`}>
											{headingText}
										</span>
									)}
								</span>
							</p>
						)}
						{headingSubText && <p className="p_reg fs-20 color17">{headingSubText}</p>}
					</div>
					<div className="d-flex gap-2">
						{slidesToShowResponsive >= 3 && showViewAllButton && ViewAllButton}
						{(sliderComponentType === SliderComponentType.COURSES
							? slidesToShowResponsive > 2
							: slidesToShowResponsive >= 2) &&
							hasTopRightButtons &&
							arrayToUse.length != 1 &&
							!showBottomSlider && (
								<div className="d-flex gap-2">
									<button
										onClick={handlePrev}
										disabled={currentSlide === 0}
										className={`border-0 bg-none ${currentSlide === 0 ? 'disabled' : ''}`}>
										<Image
											src={toAbsoluteUrl('/media/icons/common-slider-prev.svg')}
											alt="Left_Slider"
										/>
									</button>
									<button
										onClick={handleNext}
										disabled={currentSlide === arrayToUse.length - slidesToShowResponsive}
										className={`border-0 bg-none ${
											currentSlide === arrayToUse.length - slidesToShowResponsive ? 'disabled' : ''
										}`}>
										<Image
											src={toAbsoluteUrl('/media/icons/common-slider-next.svg')}
											alt="Right_Slider"
										/>
									</button>
								</div>
							)}
					</div>
				</div>
				<div className="col-lg-12 commonSlider d-flex flex-column align-items-center">
					<Slider {...settings} className="mx-2" ref={sliderRef}>
						{SliderContent({ sliderComponentType: sliderComponentType })}
					</Slider>
					{showBottomSlider && (
						<div className="d-flex flex-column align-items-center gap-4">
							<div className="w-vw-10">
								<SliderProgressPagination
									startingOffset={startingOffset}
									individualProgressPercentage={individualProgressPercentage}
								/>
							</div>
							{showViewAllButton && ViewAllButton}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default CommonSlider;
