import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormikProps, useFormik } from 'formik';
import * as Yup from 'yup';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import toast from 'react-hot-toast';
import { useUserContext } from 'context/UserContext';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { getUserByToken } from 'pages/auth/core/_requests';
import './PersonalDetail.css';
import { UsersGendersEnum } from 'components/_v2/Course/type';

import Button from '../CommonComponents/Button';
import { ButtonBorderType, ButtonType } from '../CommonComponents/types';
import { NewUpdateStudentProfileInput } from 'generated/types';
import { UserSigninType } from 'types/globalTypes';
import CommonSelect from 'components/_v2/CommonComponents/CommonSelect';
import Loading from 'components/_v2/CommonComponents/Loading';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useUserAddressContext } from 'context/UserAddressContext';
import { Modal, Tooltip } from 'react-bootstrap';
import ImageCropHelper from 'components/_v2/CommonComponents/ImageCrop/ImageCropHelper';

const UPDATE_IMAGE = gql`
	mutation UpdateImage($imageUploadInput: ImageUploadInput!) {
		UpdateImage(imageUploadInput: $imageUploadInput)
	}
`;

const UPDATE_STUDENT_PROFILE = gql`
	mutation NewUpdateStudentProfile($input: NewUpdateStudentProfileInput!) {
		NewUpdateStudentProfile(NewupdateStudentProfileInput: $input)
	}
`;

const SEND_MOBILE_OTP = gql`
	mutation sendOtp($mobileNo: String!) {
		sendOtp(mobileNo: $mobileNo)
	}
`;

const SEND_EMAIL_OTP = gql`
	mutation sendEmailOtp {
		sendEmailOtp
	}
`;

const VERIFY_EMAIL_OTP = gql`
	mutation verifyEmailOtp($otp: String!) {
		verifyEmailOtp(otp: $otp)
	}
`;

const VERIFY_OTP = gql`
	mutation verifyOtp($otp: String!) {
		verifyOtp(otp: $otp)
	}
`;

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

const genderOptions = [
	{ value: 'MALE', label: 'Male' },
	{ value: 'FEMALE', label: 'Female' },
	{ value: 'OTHER', label: 'Other' },
];

interface FormValues {
	fullname: string;
	birthdate: Date;
	phoneNumber: string;
	email: string;
	countryId: string;
	stateId: string;
	cityId: string;
	gender: UsersGendersEnum;
	pincode: string | null;
	otpCode: string | null;
}

const PersonalDetail = () => {
	const { user, setUser, localStorageAuthToken } = useUserContext();
	const {
		selectedCountryId,
		selectedStateId,
		selectedCityId,
		countries,
		setCities,
		cities,
		setSelectedStateId,
		setSelectedCountryId,
		setSelectedCityId,
		states,
		setStates,
		allCountryData,
	} = useUserAddressContext();
	const [mobileNo, setMobileNo] = React.useState('');
	const [image, setImage] = useState<string | null>(null);
	const [displayImage, setDisplayImage] = useState<string | null>(null);
	const [hasImageChanged, setHasImageChanged] = useState(false);

	const [imageCropModal, setImageCropModal] = useState<boolean>(false);

	const [otp, setOtp] = useState('');
	const [modalOpen, setModalOpen] = useState(false);
	const [modalEmailOpen, setModalEmailOpen] = useState(false);
	const [seconds, setSeconds] = useState(89);
	const [canResend, setCanResend] = useState(false);
	const [isVerifyingMobileOrEmail, setIsVerifyingMobileOrEmail] = useState<boolean>(false);

	const [updateImage, { loading: updatingImageLoading }] = useMutation(UPDATE_IMAGE);
	const [updateStudentProfile] = useMutation<NewUpdateStudentProfileInput>(UPDATE_STUDENT_PROFILE);
	const [sendMobileOtp] = useMutation(SEND_MOBILE_OTP);
	const [verifyOtp] = useMutation(VERIFY_OTP);
	const [sendEmailOtp] = useMutation(SEND_EMAIL_OTP);
	const [verifyEmailOtp] = useMutation(VERIFY_EMAIL_OTP);

	const userSignInTypeEmail = useMemo(() => user?.signinType === UserSigninType.EMAIL, [user]);
	const fileInputRef = useRef(null);

	const renderTooltip = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Your sign-up method is Google. Email change is not available.
		</Tooltip>
	);

	const validationSchema = Yup.object({
		fullname: Yup.string()
			.required('Please enter your full name')
			.min(3, 'Full Name must be greater than 3 characters')
			.max(100, 'Full Name must be shorter than 100 characters!')
			.matches(/^[A-Za-z\s]+$/, 'Only Character Allow'),
		birthdate: Yup.date()
			.typeError('Please select a valid date')
			.nullable()
			.required('Please select your Birth Date')
			.max(new Date(Date.now()), 'Birth Date cannot be in the future'),
		phoneNumber: Yup.string()
			.required('Please enter your phone number')
			.matches(/^\d{10}$/, 'Phone Number must be 10 digits'),
		email: Yup.string().email('Invalid email address').required('Please enter your email'),
		countryId: Yup.string().required('Please select your country'),
		stateId: Yup.string().required('Please select your state'),
		cityId: Yup.string().required('Please select your city'),
		gender: Yup.string()
			.required('Please select your gender')
			.oneOf(
				[UsersGendersEnum.MALE, UsersGendersEnum.FEMALE, UsersGendersEnum.OTHER],
				'Gender is required',
			),
		otpCode: Yup.string()
			.matches(/^\d{6}$/, 'OTP must be 6 digits long')
			.nullable()
			.notRequired(),
	});

	useEffect(() => {
		if (user && user.userProfile && user.userProfile.length > 0) {
			const imageUrl = user.userProfile[0].imageUrl;
			setDisplayImage(imageUrl);
		}
	}, [user]);

	const formik: FormikProps<FormValues> = useFormik({
		initialValues: {
			fullname: user?.fullName,
			birthdate: user?.userProfile[0]?.dob,
			phoneNumber: user?.userProfile[0]?.mobileNo || '',
			email: user?.email || '',
			countryId: user?.userProfile[0]?.countryId || 'Select Country',
			stateId: user?.userAddress[0]?.stateId || '',
			cityId: user?.userAddress[0]?.cityId || '',
			gender: user?.userProfile[0]?.gender || UsersGendersEnum.OTHER,
			pincode: user?.userAddress[0]?.zipCode || null,
			otpCode: null,
		},
		enableReinitialize: true,
		validationSchema: validationSchema,
		onSubmit: async (values, { resetForm }) => {
			try {
				await updateStudentProfile({
					variables: {
						input: {
							name: values.fullname,
							dob: values.birthdate,
							mobileNo: values.phoneNumber,
							email: values.email,
							countryId: values.countryId,
							stateId: selectedStateId,
							cityId: selectedCityId,
							gender: values.gender,
							pincode: values.pincode ?? '',
						},
					},
					onCompleted: async () => {
						toast.success('Profile updated successfully');

						const { data } = await getUserByToken(localStorageAuthToken);

						if (data?.data?.getMe) {
							setUser(data.data.getMe);
						}
						resetForm({
							values: {
								fullname: values.fullname,
								birthdate: values.birthdate,
								phoneNumber: values.phoneNumber,
								email: values.email,
								countryId: values.countryId,
								stateId: values.stateId,
								cityId: values.cityId,
								gender: values.gender,
								pincode: values.pincode,
								otpCode: null,
							},
						});
					},
				});
			} catch (error) {
				console.error('Error updating profile', error);
				toast.error(error?.message);
			}
		},
	});

	const handleVerifyClick = async () => {
		try {
			const mobileNo = formik.values.phoneNumber;
			setIsVerifyingMobileOrEmail(true);
			setMobileNo(mobileNo);
			await sendMobileOtp({
				variables: {
					mobileNo,
				},
				onCompleted: () => {
					setIsVerifyingMobileOrEmail(false);
					toast.success('OTP sent successfully');
					setModalOpen(true);
					setSeconds(89);
				},
				onError: (error: ApolloError) => {
					setIsVerifyingMobileOrEmail(false);
					toast.error(error.message);
				},
			});
		} catch (error) {
			console.error('Error sending OTP', error);
			toast.error('Failed to send OTP');
		}
	};

	const handleEmailVerifyClick = async () => {
		try {
			const email = formik.values.email; // Get the current mobile number from the form
			setMobileNo(email); // Set the mobileNo state variable
			setIsVerifyingMobileOrEmail(true);
			await sendEmailOtp({
				onCompleted: () => {
					setIsVerifyingMobileOrEmail(false);
					toast.success('OTP sent successfully');
					setModalEmailOpen(true); // Open the modal
				},
				onError: () => {
					setIsVerifyingMobileOrEmail(false);
				},
			});
		} catch (error) {
			console.error('Error sending OTP', error);
			toast.error('Failed to send OTP');
		}
	};
	const handleOtpVerification = async () => {
		try {
			await verifyEmailOtp({ variables: { otp } });
			toast.success('Email verified successfully');
			setModalEmailOpen(false);
			const { data } = await getUserByToken(localStorageAuthToken);
			if (data?.data?.getMe) {
				setUser(data.data.getMe); // Update the user context with the new data
			}
		} catch (error) {
			console.error('Error verifying OTP', error);
			toast.error('OTP does not match');
		}
	};

	const handleVerifyOtp = async () => {
		try {
			await verifyOtp({ variables: { otp: formik.values.otpCode } });
			toast.success('Mobile Number verified successfully');
			setModalOpen(false);
			const { data } = await getUserByToken(localStorageAuthToken);
			if (data?.data?.getMe) {
				setUser(data.data.getMe); // Update the user context with the new data
			}
		} catch (error) {
			const errorMessage = error?.graphQLErrors?.[0]?.message || 'An error occurred';
			console.error('Error verifying OTP:', errorMessage);
			toast.error(errorMessage);
		}
	};

	const handleVerificationClose = () => {
		setModalOpen(false);
	};

	const handleEmailVerificationClose = () => {
		setModalEmailOpen(false);
	};

	const handleImageChange = (e: any) => {
		if (e.target.files.length) {
			const file = e.target.files[0];
			const reader: any = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => {
				setImage(reader.result);
				setImageCropModal(true);
			};
		}
	};

	const handleImageUpload = async () => {
		if (!displayImage) return;

		const imageData = {
			newImage: {
				name: 'profile-image.png',
				content: displayImage.split(',')[1],
			},
		};
		setDisplayImage(image);
		setHasImageChanged(false);

		try {
			await updateImage({
				variables: {
					imageUploadInput: imageData,
				},
				onCompleted: async () => {
					setImage(null);
					setHasImageChanged(false);

					const { data } = await getUserByToken(localStorageAuthToken);
					if (data?.data?.getMe) {
						setUser(data.data.getMe);
					}

					toast.success('Image updated successfully');
				},
				onError: () => {
					setHasImageChanged(true);
					throw new Error();
				},
			});
		} catch (error) {
			console.error('Error updating image', error);
			setHasImageChanged(false);
			toast.error('Failed to update image');
		}
	};

	useEffect(() => {
		if (seconds > 0) {
			const timer = setTimeout(() => setSeconds(seconds - 1), 1000);
			return () => clearTimeout(timer);
		} else {
			setCanResend(true);
		}
	}, [seconds]);

	const handleResendMobile = () => {
		setSeconds(89);
		setCanResend(false);
		handleVerifyClick();
	};
	const handleResendEmail = () => {
		setSeconds(89);
		setCanResend(false);
		handleEmailVerifyClick();
	};

	const handleSelectValueChange = async ({
		id,
		selectedOption,
	}: {
		id: string;
		selectedOption: { value: string; label: string };
	}) => {
		await formik.setFieldValue(id, selectedOption.value);
		await formik.setFieldTouched(id, true);
		await formik.validateField(id);
	};

	const handleTriggerClick = () => {
		fileInputRef.current.click();
	};

	return (
		<div className="row mt-5">
			<form onSubmit={formik.handleSubmit}>
				<div className="d-flex align-items-center dp-image-layout">
					<div
						className={`position-relative ${updatingImageLoading ? 'disabled' : ''}`}
						onClick={handleTriggerClick}>
						<img
							src={displayImage || toAbsoluteUrl('/media/header/static-user.svg')}
							alt="Profile_Image"
							className="cursor-pointer"
							style={{ width: '100px', height: '100px', borderRadius: '50%' }}
						/>
						<div
							style={{ borderRadius: '50%', width: '30px', height: '30px', right: 0, bottom: 0 }}
							className="align-items-center d-flex justify-content-center position-absolute cursor-pointer bg-black">
							<img src={toAbsoluteUrl('/media/svg/misc/camera.svg')} className="w-75 h-75" />
						</div>
						<div
							className="align-items-center d-flex justify-content-center position-absolute"
							style={{ top: '30%', left: '25%' }}>
							{updatingImageLoading && <Loading height="50px" width="50px" />}
						</div>
					</div>
					<input
						type="file"
						accept="image/*"
						onChange={handleImageChange}
						className="d-none"
						id="imageUpload"
						ref={fileInputRef}
					/>
					<div className="d-flex profile-change-btn">
						{hasImageChanged && (
							<>
								<Button
									buttonText="Re-Crop Image"
									buttonType={ButtonType.SECONDARY_BUTTON}
									classes="g_reg ms-md-4 ms-2 fs-14"
									handleClick={() => {
										setImageCropModal(true);
									}}
									type="button"
									px="16px"
									py="8px"
								/>
								<Button
									buttonText="Save Image"
									buttonType={ButtonType.PRIMARY_BUTTON}
									classes="g_reg ms-md-4 ms-2 fs-14 bg-primaryColor white"
									handleClick={handleImageUpload}
									px="16px"
									py="8px"
								/>
							</>
						)}
					</div>
				</div>
				<div className="row gy-lg-4 mt-3">
					<div className="col-12 col-md-6 col-lg-4 mt-md-4 mt-2">
						<label htmlFor="fullname" className="profile-labels g_mid">
							Full Name
						</label>
						<span className="text-danger">*</span>
						<br />
						<input
							className="input-box-style form-control g_reg"
							type="text"
							name="fullname"
							pattern="[A-Za-z\s\(\)]+"
							title="Only Character Allow"
							placeholder="Enter Full Name"
							{...formik.getFieldProps('fullname')}
						/>
						{formik.touched.fullname && formik.errors.fullname ? (
							<div className="text-danger fs-7 mt-1">{String(formik.errors.fullname)}</div>
						) : null}
					</div>
					<div className="col-12 col-md-6 col-lg-4 mt-md-4 mt-2">
						<CommonSelect
							handleChange={(selectedOption: { value: string; label: string }) =>
								handleSelectValueChange({ id: 'gender', selectedOption })
							}
							options={genderOptions}
							id="gender"
							isRequired
							isSearchable={false}
							formik={formik}
							label="Gender"
						/>
					</div>
					<div className="col-12 col-md-6 col-lg-4 mt-md-4 mt-2">
						<label htmlFor="birthdate" className="profile-labels g_mid">
							Birth Date
						</label>
						<span className="text-danger">*</span>
						<br />
						<DatePicker
							clearIcon={null}
							className={`form-control g_reg show ${
								formik.values.birthdate ? 'border-color16' : ''
							}`}
							onChange={(date) => {
								formik.setFieldValue('birthdate', date);
							}}
							maxDate={new Date()}
							value={formik.values.birthdate}
							dayPlaceholder="dd"
							monthPlaceholder="mm"
							yearPlaceholder="yyyy"
							format="dd-MM-yyyy"
							calendarIcon={
								<img src={toAbsoluteUrl('/media/svg/shapes/calendar.svg')} alt="Calendar" />
							}
						/>
						{formik.touched.birthdate && formik.errors.birthdate ? (
							<div className="text-danger fs-7 mt-1">{String(formik.errors.birthdate)}</div>
						) : null}
					</div>
					<div className="col-12 col-md-6 col-lg-4 mt-md-4 mt-2">
						<label htmlFor="phoneNumber" className="profile-labels g_mid">
							Phone Number
							<span className="text-danger">*</span>
							{user?.isMobileNumberVerified ? (
								<span className="veri-text g_mid ms-2">
									<img src={toAbsoluteUrl('/media/logo/veri-new.png')} alt="" className="me-1" />
									Verified
								</span>
							) : (
								<span
									className={`${isVerifyingMobileOrEmail ? 'disabled' : ''} v-text g_mid ms-2`}
									role="button"
									onClick={handleVerifyClick}>
									Verify
								</span>
							)}
						</label>
						<br />
						<input
							className="input-box-style form-control g_reg"
							type="text"
							name="phoneNumber"
							pattern="[0-9]{10}"
							placeholder="Enter Phone Number"
							{...formik.getFieldProps('phoneNumber')}
							onInput={(e: React.FormEvent<HTMLInputElement>) => {
								const input = e.currentTarget;
								input.value = input.value.replace(/[^0-9]/g, '').slice(0, 10);
							}}
						/>
						{formik.touched.phoneNumber && formik.errors.phoneNumber ? (
							<div className="text-danger fs-7 mt-1">{formik.errors.phoneNumber}</div>
						) : null}
					</div>

					<div className="col-12 col-md-6 col-lg-4 mt-md-4 mt-2">
						<label htmlFor="email" className="profile-labels g_mid">
							Email
							<span className="text-danger">*</span>
							{user?.isVerified ? (
								<span className="veri-text g_mid ms-2">
									<img src={toAbsoluteUrl('/media/logo/veri-new.png')} alt="" className="me-1" />
									Verified
								</span>
							) : (
								<span
									role="button"
									className={`${isVerifyingMobileOrEmail ? 'disabled' : ''} v-text g_mid ms-2`}
									onClick={handleEmailVerifyClick}>
									Verify
								</span>
							)}
						</label>
						<br />
						{userSignInTypeEmail ? (
							<input
								className="input-box-style form-control g_reg"
								type="text"
								name="email"
								placeholder="Enter Email"
								{...formik.getFieldProps('email')}
							/>
						) : (
							<OverlayTrigger
								placement="auto"
								delay={{ show: 250, hide: 250 }}
								overlay={renderTooltip}>
								<input
									className="input-box-style form-control g_reg"
									type="text"
									name="email"
									disabled={!userSignInTypeEmail}
									placeholder="Enter Email"
									{...formik.getFieldProps('email')}
								/>
							</OverlayTrigger>
						)}

						{formik.touched.email && formik.errors.email ? (
							<div className="text-danger fs-7 mt-1">{formik.errors.email}</div>
						) : null}
					</div>

					<div className="col-12 col-md-6 col-lg-4 mt-md-4 mt-2">
						<CommonSelect
							handleChange={async (selectedOption: { value: string; label: string }) => {
								await handleSelectValueChange({ id: 'countryId', selectedOption });
								setSelectedCountryId(selectedOption.value);
								const newCountryData = allCountryData.find(
									(country) => country.id === selectedOption.value,
								);
								const newCountryStates = newCountryData.states.map((state) => ({
									label: state.stateName,
									value: state.id,
								}));
								setStates(newCountryStates);

								await handleSelectValueChange({
									id: 'stateId',
									selectedOption: newCountryStates[0],
								});
								const newStatesCities = newCountryData.states[0].cities.map((city) => ({
									label: city.cityName,
									value: city.id,
								}));
								setCities(newStatesCities);
								await handleSelectValueChange({
									id: 'cityId',
									selectedOption: newStatesCities[0],
								});
							}}
							options={countries}
							id="countryId"
							isRequired
							isSearchable={true}
							formik={formik}
							label="Country"
						/>
					</div>

					<div className="col-12 col-md-6 col-lg-4 mt-md-4 mt-2">
						<CommonSelect
							handleChange={async (selectedOption: { value: string; label: string }) => {
								setSelectedStateId(selectedOption.value);
								await handleSelectValueChange({ id: 'stateId', selectedOption });
								const stateData = allCountryData
									.find((country) => country?.id === selectedCountryId)
									?.states?.find((state) => state?.id === selectedOption.value);
								const cityData = stateData?.cities?.map((city) => ({
									value: city.id,
									label: city.cityName,
								}));
								setCities(cityData);
								setSelectedCityId(cityData[0].value);
								await handleSelectValueChange({
									id: 'cityId',
									selectedOption: {
										value: cityData[0].value,
										label: cityData[0].label,
									},
								});
							}}
							options={states}
							id="stateId"
							isRequired
							isSearchable
							formik={formik}
							label="State"
						/>
					</div>

					<div className="col-12 col-md-6 col-lg-4 mt-md-4 mt-2">
						<CommonSelect
							handleChange={async (selectedOption: { value: string; label: string }) => {
								await handleSelectValueChange({ id: 'cityId', selectedOption });
								setSelectedCityId(selectedOption.value);
							}}
							options={cities}
							id="cityId"
							isRequired
							isSearchable
							formik={formik}
							label="City"
						/>
					</div>

					<div className="col-12 col-md-6 col-lg-4 mt-md-4 mt-2">
						<label htmlFor="pincode" className="profile-labels g_mid">
							Pincode
						</label>
						<br />
						<input
							className="input-box-style form-control g_reg"
							type="text"
							name="pincode"
							placeholder="Enter Pincode"
							onInput={(e: React.FormEvent<HTMLInputElement>) => {
								const input = e.currentTarget;
								input.value = input.value.replace(/[^0-9]/g, '').slice(0, 6);
							}}
							{...formik.getFieldProps('pincode')}
						/>
						{formik.touched.pincode && formik.errors.pincode ? (
							<div className="text-danger fs-7 mt-1">{formik.errors.pincode}</div>
						) : null}
					</div>
				</div>

				<div className="row mt-4">
					<div className="col-12">
						<Button
							buttonText={
								<div className="d-flex justify-content-center align-items-center flex-row gap-2">
									{formik.isSubmitting && formik.isValid ? <Loading width={20} /> : <></>}
									Save Changes
								</div>
							}
							buttonType={ButtonType.PRIMARY_BUTTON}
							classes={`fs-16 g_mid ${!formik.dirty ? 'disabled' : ''}`}
							buttonBorderType={ButtonBorderType.BLACK_BORDER}
							px="26px"
							py="8px"
							type="submit"
							disabled={!formik.dirty}
						/>
					</div>
				</div>
			</form>
			{modalOpen && (
				<div className="modal-backdrop">
					<div
						className="modal fade show"
						id="exampleModalToggle"
						aria-hidden="true"
						aria-labelledby="exampleModalToggleLabel"
						tabIndex={-1}
						style={{ display: 'block' }}>
						<div className="modal-dialog modal-dialog-centered mx-auto ">
							<div className="modal-content container">
								<div className="modal-header border-0">
									<div className="mx-auto text-center">
										<img src={toAbsoluteUrl('/media/logo/veri.png')} alt="Verification" />
										<h2 className="veri-title lora">Verification</h2>
										<p className="veri-label veri-otp-layout g_reg">
											Please enter the 6-digit code sent to <br />
											<span className="veri-label-strong">{mobileNo}</span>
										</p>
									</div>
								</div>

								<form>
									<div className="fv-row mb-10 px-4">
										<label className="label-style g_reg">
											OTP <span className="text-danger">*</span>
										</label>
										<input
											className="form-control input-inner-style g_reg"
											name="otpCode"
											placeholder="Enter OTP"
											type="password"
											value={formik.values.otpCode}
											onChange={(e) => {
												const numericValue = e.target.value.replace(/[^0-9]/g, ''); // Allow only numbers
												formik.setFieldValue('otpCode', numericValue); // Update Formik's value
											}}
											onBlur={formik.handleBlur}
										/>
										<div className="veri-desc mt-2 mb-2 text-start">
											{canResend ? (
												<button className="btn resend-btn" onClick={handleResendMobile}>
													Resend OTP
												</button>
											) : (
												<>
													Please wait&nbsp;
													<span className="veri-desc-strong">{seconds}s</span>
													&nbsp;before requesting another code
												</>
											)}
										</div>
										{formik.touched.otpCode && formik.errors.otpCode ? (
											<div className="text-danger fs-7">{formik.errors.otpCode}</div>
										) : null}
									</div>
									<div className="col-12 flex-column-reverse d-md-block d-flex mt-4 mb-4 gap-4 text-center">
										<div className="row">
											<div className="col-6">
												<Button
													buttonText="Cancel"
													buttonType={ButtonType.GREY_BUTTON}
													classes="g_mid fs-16 w-100"
													buttonBorderType={ButtonBorderType.GREY_BORDER}
													disabled={formik.isSubmitting}
													handleClick={() => {
														handleVerificationClose();
													}}
												/>
											</div>
											<div className="col-6">
												<Button
													buttonText="Continue"
													buttonType={ButtonType.PRIMARY_BUTTON}
													classes="g_mid fs-16 w-100"
													buttonBorderType={ButtonBorderType.BLACK_BORDER}
													disabled={!formik.values.otpCode || !!formik.errors.otpCode}
													handleClick={async (e) => {
														e.preventDefault();
														await handleVerifyOtp();
													}}
												/>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			)}

			{modalEmailOpen && (
				<div className="modal-backdrop">
					<div
						className="modal fade show"
						id="exampleModalToggle"
						aria-hidden="true"
						aria-labelledby="exampleModalToggleLabel"
						tabIndex={-1}
						style={{ display: 'block' }}>
						<div className="modal-dialog modal-dialog-centered mx-auto ">
							<div className="modal-content container">
								<div className="modal-header border-0">
									<div className="mx-auto text-center">
										<img src={toAbsoluteUrl('/media/logo/veri.png')} alt="Verification" />
										<h2 className="veri-title lora">Verification</h2>
										<p className="veri-label veri-otp-layout g_reg">
											Please enter the 6-digit code sent to
											<br />
											<span className="veri-label-strong">{mobileNo}</span>
										</p>
									</div>
								</div>

								<form>
									<div className="fv-row mb-10 px-4">
										<label className="label-style g_reg">
											OTP <span className="text-danger">*</span>
										</label>
										<input
											className="form-control input-inner-style g_reg"
											name="otp"
											placeholder="Enter OTP"
											type="password"
											value={otp}
											onChange={(e) => setOtp(e.target.value)}
										/>
										<div className="veri-desc mt-2 mb-4 text-start">
											{canResend ? (
												<button className="btn resend-btn" onClick={handleResendEmail}>
													Resend OTP
												</button>
											) : (
												<>
													Please wait&nbsp;
													<span className="veri-desc-strong">{seconds}s</span>
													&nbsp;before requesting another code
												</>
											)}
										</div>
									</div>
									<div className="col-12 mb-4 text-center">
										<button
											className="btn g_mid me-3 new-btn-fg-cancel-layout"
											type="button"
											onClick={handleEmailVerificationClose}
											disabled={formik.isSubmitting}>
											Cancel
										</button>
										<button
											className="btn bg-color2 g_mid new-btn-fg-layout"
											type="button"
											onClick={handleOtpVerification}>
											Continue
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			)}

			{imageCropModal && (
				<Modal
					show={imageCropModal}
					onHide={() => {
						setImageCropModal(false);
					}}
					keyboard
					animation={false}
					centered
					size="xl">
					<Modal.Header closeButton>Choose Image Crop</Modal.Header>
					<ImageCropHelper
						imgSrc={image}
						circularCrop
						aspect={1}
						onComplete={(imageAfterCrop: string | null) => {
							setHasImageChanged(true);
							setDisplayImage(imageAfterCrop);
							setImageCropModal(false);
						}}
					/>
				</Modal>
			)}
		</div>
	);
};

export default PersonalDetail;
