import ImageModalOpener from './ImageModalOpener';

const QuizQuestion = ({
	quizQuestion = '',
	quizQuestionImages = [],
}: {
	quizQuestion?: string;
	quizQuestionImages?: string[];
}) => {
	return (
		<div className="d-flex flex-column">
			{quizQuestion && <p className="g_mid">{quizQuestion}</p>}
			{quizQuestionImages.length > 0 &&
				quizQuestionImages.map((imageSrc) => (
					<ImageModalOpener imageSrc={imageSrc} key={imageSrc} height="20px" width="20px" />
				))}
		</div>
	);
};

export default QuizQuestion;
