import { gql } from '@apollo/client';

export const GETCOURSEBYID = gql`
	query requests_getPublicCourseByIdQuery($id: String!) {
		getPublicCourseById(id: $id) {
			id
			name
			slug
			shortDescription
			description
			sortOrder
			status
			images {
				thumb
				medium
				large
				original
			}
			previewVideo
			amount
			sellPrice
			startDatetime
			endDatetime
			totalSeat
			costType
			address
			meetingLink
			meetingNumber
			meetingPassword
			courseEnrollmentCount
			hasQuiz
			requirements
			courseCategoryId
			courseCategory {
				id
				name
				sortNumber
				imageUrl
				slug
				parentId
				parent {
					id
					name
				}
			}
			courseSubCategoryId
			courseSubCategory {
				id
				name
				sortNumber
				imageUrl
				slug
				parentId
				parent {
					id
					name
				}
			}
			courseLevelId
			courseLevel {
				id
				name
				slug
			}
			courseModeId
			courseMode {
				id
				name
			}
			courseTypeId
			courseType {
				id
				name
				slug
			}
			courseLanguages {
				id
				name
				shortCode
				slug
				status
			}
			mentors {
				mentor {
					email
					firstName
					id
					lastName
					userProfile {
						gender
						userId
						imageUrl
						description
						country {
							id
							countryName
						}
					}
				}
			}
			objectives {
				objective
			}
			sections {
				id
				name
				sortNumber
				introduction
				courseId
				createdAt
				updatedAt
				createdById
				updatedById
				lessons {
					id
					contentUrl
					description
					type
					quiz {
						totalMarks
						questions {
							duration
							question
						}
					}
					introduction
					name
					duration
				}
			}
			courseSkills {
				id
				name
				slug
				status
			}
			highlights {
				id
				courseId
				highlightIcon
				highlightText
			}
		}
	}
`;

export const GETCOURSEBYSLUG = gql`
	query requests_getPublicCourseBySlugQuery($slug: String!) {
		getPublicCourseBySlug(slug: $slug) {
			id
			name
			slug
			shortDescription
			completed
			previewVideo
			description
			sortOrder
			status
			images {
				thumb
				medium
				large
				original
			}
			courseProgress {
				id
				userId
				courseId
				lessonId
				sectionId
				quizId
				duration
				isCourseCompleted
				isLessonCompleted
				isSectionCompleted
				isQuizCompleted
				createdAt
				updatedAt
			}
			amount
			sellPrice
			startDatetime
			endDatetime
			totalSeat
			costType
			address
			meetingLink
			meetingNumber
			meetingPassword
			hasQuiz
			requirements
			courseCategoryId
			courseCategory {
				id
				name
				sortNumber
				imageUrl
				slug
				parentId
				parent {
					id
					name
				}
			}
			courseSubCategoryId
			courseSubCategory {
				id
				name
				sortNumber
				imageUrl
				slug
				parentId
				parent {
					id
					name
				}
			}
			courseLevelId
			courseLevel {
				id
				name
				slug
			}
			courseModeId
			courseMode {
				id
				name
			}
			courseTypeId
			courseType {
				id
				name
				slug
			}
			courseLanguages {
				id
				name
				shortCode
				slug
				status
			}
			mentors {
				mentor {
					email
					firstName
					id
					lastName
					userProfile {
						gender
						userId
						imageUrl
						description
						country {
							id
							countryName
						}
					}
				}
			}
			objectives {
				objective
			}
			sections {
				id
				name
				sortNumber
				introduction
				courseId
				createdAt
				updatedAt
				createdById
				updatedById
				completed
				lessons {
					id
					contentUrl
					description
					sectionId
					type
					completed
					quiz {
						id
						title
						type
						status
						hasTimeLimit
						eligibleMarksForCertificate
						totalMarks
						questions {
							id
							questionImages
							hasMultipleAnswers
							duration
							question
							mark
							questionOrder
							quizId
							options {
								optionText
								optionImage
								__typename
							}
							__typename
						}
						__typename
					}
					introduction
					name
					duration
					__typename
				}
			}
			courseSkills {
				id
				name
				slug
				status
				__typename
			}
			highlights {
				id
				courseId
				highlightIcon
				highlightText
				__typename
			}
			__typename
		}
	}
`;

export const ADDTOWISHLIST = gql`
	mutation requests_addtoWishListMutation($courseId: String!) {
		addToWishlist(courseId: $courseId)
	}
`;

export const ADDTOCART = gql`
	mutation requests_addToCartMutation($courseId: String!) {
		addToCart(CourseId: $courseId)
	}
`;
export const GETCOURSEENROLLMENTSTATUS = gql`
	query getCourseEnrollmentStatus($courseId: String!) {
		getCourseEnrollmentStatus(course_id: $courseId)
	}
`;

export const UPSERT_COURSE_PROGRESS = gql`
	mutation UpsertCourseProgress($upsertCourseProgressInput: UpsertCourseProgressInput!) {
		upsertCourseProgress(upsertCourseProgressInput: $upsertCourseProgressInput)
	}
`;

export const CHANGE_PASSWORD = gql`
	mutation ChangePassword($changePasswordInput: ChangePasswordInput!) {
		changePassword(changePasswordInput: $changePasswordInput)
	}
`;

export const RESET_PASSWORD_TOKEN = gql`
	mutation ResetPasswordToken($forgotPasswordInput: ForgotPasswordInput!) {
		resetPasswordToken(forgotPasswordInput: $forgotPasswordInput)
	}
`;

export const RESET_PASSWORD = gql`
	mutation ResetForgotPassword($resetForgotPasswordInput: ResetForgotPasswordInput!) {
		resetForgotPasswordPublic(resetForgotPasswordInput: $resetForgotPasswordInput)
	}
`;

export const SUBMIT_QUIZ_MUTATION = gql`
	mutation SubmitQuiz($userQuizResultInputType: UserQuizResultInputType!) {
		submitQuiz(userQuizResultInputType: $userQuizResultInputType)
	}
`;

export const GET_QUIZ_RESULT = gql`
	query GetQuizResult($courseId: String!, $quizId: String!) {
		getQuizResult(course_id: $courseId, quiz_id: $quizId) {
			id
			userId
			courseId
			quizId
			questionAnswer {
				question
				answer
				correctAnswer
				mark
			}
			createdAt
			updatedAt
		}
	}
`;

export const SENDEMAILVERIFICATION = gql`
	mutation {
		sendEmailVerificationToken
	}
`;

const GET_COUNTRIES_WITH_CODE = gql`
	query GetCountriesWithCode {
		getCountriesWithCode {
			id
			countryName
			countryStdCode
			countryCode
			states {
				id
				countryId
				stateName
				stateCode
				cities {
					id
					stateId
					cityName
				}
			}
		}
	}
`;
