import clsx from 'clsx';
import { Formik, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import { ApolloError, useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { RESET_PASSWORD, RESET_PASSWORD_TOKEN } from '../../Courses-Page/core/requests';
import './fgpassword.css';
import Button from 'components/_v2/CommonComponents/Button';
import { ButtonBorderType, ButtonType } from 'components/_v2/CommonComponents/types';
import CommonPasswordInput from 'components/_v2/CommonComponents/CommonPasswordInput';
import Loading from 'components/_v2/CommonComponents/Loading';
import { useAuthModalContext } from 'context/AuthContext';

const ChangePasswordSchema = Yup.object().shape({
	email: Yup.string()
		.email('Please enter a valid email address')
		.min(3, 'Minimum 3 symbols')
		.max(50, 'Maximum 50 symbols')
		.required('Please enter your email'),
});

const VerificationSchema = Yup.object().shape({
	newPassword: Yup.string()
		.matches(
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
			'Password must contain at least 8 characters, one uppercase, one lowercase, one number, and one special case character',
		)
		.required('Please enter new password'),
	confirmNewPassword: Yup.string()
		.oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
		.required('Please enter confirm password'),
	otp: Yup.string().required('Please enter OTP'),
});

export const ForGotPassword = () => {
	const { toggleForgotModal, forgotPassword } = useAuthModalContext();
	const [requestPasswordResetTokenMutation] = useMutation(RESET_PASSWORD_TOKEN);
	const [resetForgotPasswordMutation] = useMutation(RESET_PASSWORD);
	const [showVerificationModal, setShowVerificationModal] = useState(false);
	const [loadingOTP, setLoadingOTP] = useState(false);
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState('');
	const [timer, setTimer] = useState(89);
	const [resendEnabled, setResendEnabled] = useState(false);

	useEffect(() => {
		let interval: any;
		if (showVerificationModal && timer > 0) {
			interval = setInterval(() => {
				setTimer(timer - 1);
			}, 1000);
		} else {
			setResendEnabled(true);
			clearInterval(interval);
		}
		return () => clearInterval(interval);
	}, [timer, showVerificationModal]);

	const initialValues = { email: '', type: 'APP' };
	const verificationInitialValues = {
		newPassword: '',
		confirmNewPassword: '',
		otp: '',
	};

	const handleSendOtp = async ({
		values,
		onCompleted,
		onError,
	}: {
		values: { email: string; type: string };
		onCompleted: () => void;
		onError: (err: ApolloError) => void;
	}) => {
		setLoadingOTP(true);
		await requestPasswordResetTokenMutation({
			variables: {
				forgotPasswordInput: {
					email: values.email,
					type: values.type,
				},
			},
			onCompleted: () => {
				onCompleted();
				setLoadingOTP(false);
			},
			onError: (err) => {
				onError(err);
				setLoadingOTP(false);
			},
		});
	};

	const formik = useFormik({
		initialValues,
		validationSchema: ChangePasswordSchema,
		onSubmit: (values, { resetForm, setSubmitting }) => {
			handleSendOtp({
				values,
				onCompleted: () => {
					toast.success('OTP sent successfully for password reset.');
					setEmail(values.email);
					setShowVerificationModal(true);
					setTimer(89); // Reset the timer when token is sent
					setResendEnabled(false); // Disable resend OTP button initially
					setSubmitting(false);
					resetForm();
				},
				onError: (err) => {
					setSubmitting(false);
					toast.error(err.message);
				},
			});
		},
	});

	const handleVerificationSubmit = async (values, { setSubmitting, setFieldValue }) => {
		setLoading(true);
		try {
			await resetForgotPasswordMutation({
				variables: {
					resetForgotPasswordInput: {
						resetPasswordKey: values.otp,
						newPassword: values.newPassword,
						confirmNewPassword: values.confirmNewPassword,
					},
				},
				onCompleted: () => {
					setLoading(false);
					toast.success('Password reset successfully.');
					setShowVerificationModal(false);
					toggleForgotModal();
					formik.resetForm();
				},
				onError: (err) => {
					setLoading(false);
					setSubmitting(false);
					setFieldValue('otp', '');
					toast.error(err.message);
				},
			});
		} catch (error) {
			toast.error('An error occurred while resetting the password.');
		}
	};

	const handleVerificationModalClose = () => setShowVerificationModal(false);

	const handleResendOTP = () => {
		handleSendOtp({
			values: { email: email, type: formik.values.type },
			onError: (err: ApolloError) => {
				setTimer(5);
				setResendEnabled(false);
				toast.error('There was some issue sending OTP!');
			},
			onCompleted: () => {
				setTimer(89);
				setResendEnabled(false);
				toast.success('OTP resent successfully.');
			},
		});
	};

	return (
		<>
			<Modal
				show={forgotPassword}
				onHide={() => {
					toggleForgotModal();
					formik.resetForm();
				}}
				centered
				className="">
				<Modal.Header className="">
					<Modal.Title className="text-center">
						<div className="text-center">
							<img src={toAbsoluteUrl('/media/logo/fgpassword.png')} alt="" />
						</div>
						<h2 className="fg-title lora">Password Reset</h2>
						<p className="fg-label g_reg">
							Enter email address associated with your HoGrowth account
						</p>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="mx-2">
					<form onSubmit={formik.handleSubmit}>
						<>
							<div className="col-12">
								<label className="label-style g_reg label-fg-layout">
									Email <span style={{ color: 'red' }}>*</span>
								</label>
								<div className="input-group">
									<input
										type={'email'}
										placeholder="Enter Email Address"
										autoComplete="off"
										{...formik.getFieldProps('email')}
										className={clsx(
											'form-control input-inner-style g_reg',
											{
												'is-invalid': formik.touched.email && formik.errors.email,
											},
											{
												'is-valid': formik.touched.email && !formik.errors.email,
											},
										)}
									/>
								</div>
								{formik.touched.email && formik.errors.email && (
									<div className="text-danger fs-7 mt-1">
										<i className="fa fa-exclamation-circle me-1"></i>
										{formik.errors.email}
									</div>
								)}
							</div>
							<div className="col-12 flex-column-reverse d-md-block d-flex mt-5 mb-4 gap-4 text-center">
								<div className="row">
									<div className="col-6">
										<Button
											buttonText="Cancel"
											buttonType={ButtonType.GREY_BUTTON}
											classes="g_mid fs-16 w-100"
											buttonBorderType={ButtonBorderType.GREY_BORDER}
											disabled={formik.isSubmitting}
											handleClick={() => {
												toggleForgotModal();
												formik.resetForm();
											}}
										/>
									</div>
									<div className="col-6">
										<Button
											buttonText={
												<span className="d-flex w-100 justify-content-center gap-2">
													{formik.isSubmitting && <Loading width={50} />}
													Continue
												</span>
											}
											buttonType={ButtonType.PRIMARY_BUTTON}
											classes="g_mid fs-16 w-100"
											buttonBorderType={ButtonBorderType.BLACK_BORDER}
											disabled={formik.isSubmitting || !formik.values.email}
										/>
									</div>
								</div>
							</div>
						</>
					</form>
				</Modal.Body>
			</Modal>

			<Modal show={showVerificationModal} onHide={handleVerificationModalClose} centered>
				<Modal.Body>
					<div className="mx-2 mt-4 text-center">
						<div>
							<img src={toAbsoluteUrl('/media/logo/veri.png')} alt="Verification" />
						</div>
						<h2 className="veri-title lora">Verification</h2>
						<p className="veri-label g_reg">
							Please enter the 6-digit code sent to <br />
							<span className="veri-label-strong">{email}</span>
						</p>
						<div className="row">
							<Formik
								initialValues={verificationInitialValues}
								validationSchema={VerificationSchema}
								onSubmit={handleVerificationSubmit}>
								{({
									isSubmitting,
									touched,
									errors,
									getFieldProps,
									values,
									setSubmitting,
									setFieldValue,
									handleSubmit,
								}) => (
									<div className="col-12 d-flex flex-column gap-sm-3 gap-1">
										<CommonPasswordInput
											name="newPassword"
											disabled={isSubmitting}
											placeholder="Enter New Password"
											label="New Password"
											labelColor="color-16"
											showValidOrInvalidOutlineClass={
												!touched.newPassword
													? ''
													: touched.newPassword && errors.newPassword
													? 'is-invalid'
													: 'is-valid'
											}
											handleSubmit={handleSubmit}
										/>
										<CommonPasswordInput
											name="confirmNewPassword"
											disabled={isSubmitting}
											placeholder="Enter Confirm Password"
											label="Confirm Password"
											labelColor="color-16"
											showValidOrInvalidOutlineClass={
												!touched.confirmNewPassword
													? ''
													: touched.confirmNewPassword && errors.confirmNewPassword
													? 'is-invalid'
													: 'is-valid'
											}
											handleSubmit={handleSubmit}
										/>
										<div className="text-start">
											<label className="label-style g_reg" htmlFor="otp">
												OTP<span className="text-danger">*</span>
											</label>
											<input
												type="text"
												className="form-control input-inner-veri-style g_reg"
												placeholder="Enter OTP"
												autoComplete="off"
												{...getFieldProps('otp')}
											/>
											{touched.otp && errors.otp && (
												<div className="text-danger fs-7 mt-1">
													<i className="fa fa-exclamation-circle me-1" />
													{errors.otp && <span>{errors.otp as string}</span>}
												</div>
											)}
										</div>
										<p className="veri-desc mb-0 text-start">
											{resendEnabled ? (
												<button
													className={`btn resend-btn m-0 border-0 p-0 align-baseline ${
														loadingOTP ? 'disabled' : ''
													}`}
													onClick={handleResendOTP}>
													Resend OTP
												</button>
											) : (
												<>
													Please wait &nbsp;
													<span className="veri-desc-strong">{timer}s</span>
													&nbsp; before requesting another code
												</>
											)}
										</p>
										<div className="row my-3 text-center">
											<div className="col-6">
												<Button
													classes="w-100"
													buttonText="Cancel"
													buttonType={ButtonType.GREY_BUTTON}
													buttonBorderType={ButtonBorderType.GREY_BORDER}
													disabled={isSubmitting}
													type="reset"
													handleClick={handleVerificationModalClose}
												/>
											</div>
											<div className="col-6">
												<Button
													buttonText={
														<span className="d-flex w-100 justify-content-center gap-2">
															{loading && <Loading width={50} />}
															Save
														</span>
													}
													buttonType={ButtonType.PRIMARY_BUTTON}
													buttonBorderType={ButtonBorderType.BLACK_BORDER}
													type="submit"
													handleClick={async () => {
														await handleVerificationSubmit(values, {
															setSubmitting,
															setFieldValue,
														});
													}}
													disabled={
														isSubmitting ||
														!values.newPassword ||
														!values.confirmNewPassword ||
														values.newPassword !== values.confirmNewPassword
													}
													classes="w-100"
												/>
											</div>
										</div>
									</div>
								)}
							</Formik>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};
