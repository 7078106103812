import React, { useEffect, useRef } from 'react';
import './NotificationPopup.css';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { useNavigate } from 'react-router-dom';
import { NotificationsObjectType } from './types';
import moment from 'moment';
import IndividualNotification from '../CommonComponents/IndividualNotification';

interface NotificationPopupProps {
	notifications: NotificationsObjectType[];
	handleClose: () => void;
}

const NotificationPopup: React.FC<NotificationPopupProps> = ({ notifications, handleClose }) => {
	const navigate = useNavigate();
	const popupRef = useRef<HTMLDivElement>(null);

	const handleViewAll = () => {
		handleClose(); // Close the popup
		navigate('/notification-list'); // Redirect to the notifications page
	};

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
				handleClose();
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleClose]);

	const notificationsToDisplay = notifications.slice(0, 3);

	return (
		<div ref={popupRef} className="notification-popup">
			<div className="notification-popup-header d-flex justify-content-between align-items-center">
				<h3 className="main-noti-title g_mid">Notifications</h3>
				<button onClick={handleClose} className="close-noti-style border-0 bg-white">
					<img src={toAbsoluteUrl('/media/notification-images/close.svg')} alt="Close_Image" />
				</button>
			</div>
			<div>
				{notifications.length === 0 ? (
					<div>
						<div className="d-flex justify-content-center">
							<img
								src={toAbsoluteUrl('/media/notification-images/no-notification.svg')}
								alt="No Notifications"
							/>
						</div>
						<div className="no-notifications-title my-3 text-center">No Notifications Yet</div>
						<div className="no-notifications-desc my-3 text-center">
							You have no notifications right now, come back later
						</div>
					</div>
				) : (
					<>
						<div className="notification-popup-content">
							{notificationsToDisplay.map((notification) => (
								<IndividualNotification notification={notification} />
							))}
						</div>
						<div className="notification-popup-footer">
							<span role="button" onClick={handleViewAll} className="g_mid">
								View All
							</span>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default NotificationPopup;
