import Loading from 'components/_v2/CommonComponents/Loading';
import { useState } from 'react';
import useIsSmallScreen from '../../../../hooks/useIsSmallScreen';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { Image } from 'react-bootstrap';
import { Course } from 'components/_v2/Course/type';
import { mentorProfileNameRenderer } from 'components/_v2/CommonComponents/helper';
import Button from 'components/_v2/CommonComponents/Button';
import { ButtonBorderType, ButtonType } from 'components/_v2/CommonComponents/types';

const LessonButtons = ({
	onNextClick,
	onPreviousClick,
	nextButtonText,
	previousButtonText,
	isFirstLessonOfFirstSection,
	isLastLessonOfLastSection,
	isDownloadCertificateButton,
	lessonName,
	course,
	allQuizCompleted,
}: {
	nextButtonText: string;
	previousButtonText: string;
	onNextClick: (setLoading: (loading: boolean) => void) => void;
	onPreviousClick: () => void;
	isFirstLessonOfFirstSection: boolean;
	isLastLessonOfLastSection: boolean;
	isDownloadCertificateButton: boolean;
	lessonName: string;
	course: Course;
	allQuizCompleted: boolean;
}) => {
	const [loading, setLoading] = useState(false);
	const isSmallScreen = useIsSmallScreen(576);
	if (isSmallScreen) {
		return (
			<div className="w-100">
				<div
					className="w-100 d-flex justify-content-between align-items-center gap-3 px-3"
					style={{ height: '56px', backgroundColor: '#FFCD001A' }}>
					{isFirstLessonOfFirstSection ? (
						<div></div>
					) : (
						<Image
							alt="prev"
							onClick={onPreviousClick}
							src={toAbsoluteUrl('/media/icons/previous-lecture.svg')}
						/>
					)}
					{lessonName}
					{isLastLessonOfLastSection ? (
						<div></div>
					) : (
						<Image
							alt="next"
							onClick={() => onNextClick(setLoading)}
							src={toAbsoluteUrl('/media/icons/next-lecture.svg')}
						/>
					)}
				</div>
				<div className="d-flex w-100 px-2 pt-2">
					{mentorProfileNameRenderer(course?.mentors, true)}
				</div>
			</div>
		);
	}

	return (
		<div className="dashedLine w-100 px-4">
			<div className="d-flex w-100 justify-content-between mb-4 mt-3">
				{isFirstLessonOfFirstSection ? (
					<div></div>
				) : (
					<Button
						buttonText={previousButtonText}
						buttonType={ButtonType.GREY_BUTTON}
						classes="g_mid fs-12"
						buttonBorderType={ButtonBorderType.GREY_BORDER}
						handleClick={onPreviousClick}
					/>
				)}
				{isLastLessonOfLastSection && !allQuizCompleted ? (
					<div></div>
				) : (
					<Button
						buttonText={
							<div className="d-flex">
								{isDownloadCertificateButton && (
									<img
										src={toAbsoluteUrl('/media/course-certificate-images/certificate-icon.svg')}
										alt="Certificate"
										className="me-2"
									/>
								)}
								{loading && isDownloadCertificateButton && <Loading width="50px" height="25px" />}
								{nextButtonText}
							</div>
						}
						buttonType={ButtonType.PRIMARY_BUTTON}
						classes="g_mid fs-12"
						buttonBorderType={ButtonBorderType.BLACK_BORDER}
						disabled={loading}
						handleClick={() => {
							if (isDownloadCertificateButton) {
								setLoading(true);
							}
							onNextClick(setLoading);
						}}
					/>
				)}
			</div>
		</div>
	);
};

export default LessonButtons;
