import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { useQuizContext } from 'context/QuizContext';
import moment from 'moment';
import { Image } from 'react-bootstrap';
import { ButtonBorderType, ButtonType } from 'components/_v2/CommonComponents/types';
import { LessonChange } from '../ViewCourseData';
import { QuizResultStatusEnum } from 'components/_v2/ViewCourseData/LessonTypesComponents/Quiz/type';
import Button from 'components/_v2/CommonComponents/Button';

const QuizCompleted = ({
	totalMarksAttained = 0,
	handleLessonChange,
	isLastLessonOfLastSection,
	allowReattempt,
	handleReattemptQuiz,
	totalQuizMarks,
	reattemptStatus,
	isCourseStateCompleted,
	eligiblePercentageForCertificate = 70,
}: {
	totalMarksAttained: number;
	handleLessonChange: (shiftType: LessonChange) => void;
	isLastLessonOfLastSection: boolean;
	allowReattempt: boolean;
	handleReattemptQuiz: () => void;
	totalQuizMarks: number;
	reattemptStatus: QuizResultStatusEnum;
	eligiblePercentageForCertificate: number;
	isCourseStateCompleted: boolean;
}) => {
	const { quizResult } = useQuizContext();

	return (
		<div className="d-flex flex-column align-items-center gap-1">
			{!allowReattempt && reattemptStatus === QuizResultStatusEnum.FAILED ? (
				<Image
					src={toAbsoluteUrl('/media/course-detail-images/quiz-failed-all-attempts.svg')}
					className="img-fluid"
					height={274}
					width={369}
				/>
			) : (
				<Image
					src={toAbsoluteUrl('/media/course-detail-images/quiz-completed.svg')}
					className="img-fluid"
					height={274}
					width={369}
				/>
			)}
			<div className="d-flex flex-column align-items-center mb-5 mt-4 gap-1">
				<p className="course-submit-text g_reg">
					Submitted on &nbsp;
					{moment(quizResult?.quizSubmitTime).format('DD MMMM YYYY')}
				</p>
				<p className="course-yourscore-style g_mid">
					{!allowReattempt
						? `Your Score: ${totalMarksAttained}`
						: `Previous Score: ${totalMarksAttained} / ${totalQuizMarks}`}
				</p>
				{allowReattempt && (
					<span className="g_mid mb-1" style={{ color: '#757575' }}>
						Required Percentage is {eligiblePercentageForCertificate}%
					</span>
				)}
				<div className="d-flex align-items-center gap-3">
					{((isLastLessonOfLastSection && isCourseStateCompleted) ||
						(!isLastLessonOfLastSection && !allowReattempt)) && (
						<Button
							buttonText="Continue Learning"
							buttonType={ButtonType.PRIMARY_BUTTON}
							classes="g_mid fs-12"
							buttonBorderType={ButtonBorderType.BLACK_BORDER}
							handleClick={() => handleLessonChange(LessonChange.NEXT)}
						/>
					)}
					{allowReattempt && (
						<Button
							buttonText="Retake Quiz"
							buttonType={ButtonType.PRIMARY_BUTTON}
							classes="g_mid fs-12"
							buttonBorderType={ButtonBorderType.BLACK_BORDER}
							handleClick={handleReattemptQuiz}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default QuizCompleted;
