import Select from 'react-select';
import { CustomDropdownIndicator } from 'components/_v2/CommonComponents/CommonSelect';
import React, { useState } from 'react';
import { useUserAddressContext } from 'context/UserAddressContext';
 
const StateAndCountryDropDowns = ({
    handleCountryChange,
    handleStateChange,
}: {
    handleCountryChange: (country: string) => void;
    handleStateChange: (state: string) => void;
}) => {
	const { countries, states } = useUserAddressContext();

	// Local state to manage user-selected values
	const [selectedCountry, setSelectedCountry] = useState('');
	const [selectedState, setSelectedState] = useState('');

	// Add placeholder options for Country and State
	const countryOptions = [
		{ value: '', label: 'Select Country' }, // Default placeholder
		...countries,
	];

	const stateOptions = [
		{ value: '', label: 'Select State' }, // Default placeholder
		...states,
	];

	// Handlers to update local state and trigger parent callbacks
	const handleCountrySelect = (option: { value: string }) => {
		setSelectedCountry(option?.value || '');
		handleCountryChange(option?.value || '');
	};

	const handleStateSelect = (option: { value: string }) => {
		setSelectedState(option?.value || '');
		handleStateChange(option?.value || '');
	};

	return (
		<div className="row">
			<div className="col-12 col-sm-6">
				<label htmlFor="country" className="profile-labels g_mid fs-16">
					Country
				</label>
				<span className="text-danger">*</span>
				<br />
				<Select
					id="country"
					name="country"
					options={countryOptions}
					value={countryOptions.find((country) => country.value === selectedCountry) || countryOptions[0]}
					onChange={handleCountrySelect}
					placeholder="Select Country"
					classNamePrefix="react-select"
					styles={{
						control: (provided) => ({
							...provided,
							lineHeight: '35px',
						}),
					}}
					isSearchable={true}
					components={{
						DropdownIndicator: CustomDropdownIndicator,
					}}
				/>
			</div>
			<div className="col-12 col-sm-6">
				<label htmlFor="state" className="profile-labels g_mid fs-16">
					State
				</label>
				<span className="text-danger">*</span>
				<br />
				<Select
					id="state"
					name="state"
					options={stateOptions}
					value={stateOptions.find((state) => state.value === selectedState) || stateOptions[0]}
					onChange={handleStateSelect}
					placeholder="Select State"
					classNamePrefix="react-select"
					isSearchable={true}
					styles={{
						control: (provided) => ({
							...provided,
							lineHeight: '35px',
						}),
					}}
					components={{
						DropdownIndicator: CustomDropdownIndicator,
					}}
				/>
			</div>
		</div>
	);
};
 
export default StateAndCountryDropDowns;