import React, { useEffect, useState } from 'react';
import SubmitEditStarRatingsModal from 'components/_v2/CommonComponents/SubmitStarRatings/SubmitEditStarRatingsModal';
import Stars from 'components/_v2/CommonComponents/SubmitStarRatings/Stars';
import { RatingStatusEnum } from 'components/_v2/Course/type';

interface SubmitStarRatingsProps {
	currentRatings?: number;
	courseDescription: string;
	courseName: string;
	onSubmit: (newRating: number, description: string) => void;
	disableModal?: boolean;
	courseRatingStatus: RatingStatusEnum;
}

const SubmitStarRatings = ({
	currentRatings = 0,
	onSubmit,
	courseName,
	disableModal = false,
	courseDescription = '',
	courseRatingStatus = RatingStatusEnum.PENDING,
}: SubmitStarRatingsProps) => {
	const [hoveredRating, setHoveredRating] = useState<number | null>(null);
	const [newStarRating, setNewStarRating] = useState<number>(currentRatings);

	useEffect(() => {
		if (currentRatings) {
			setNewStarRating(currentRatings);
		}
	}, [currentRatings]);

	const [showModal, setShowModal] = useState<boolean>(false);

	return (
		<div>
			<span className="color17 g_reg">{currentRatings ? 'Your Rating' : 'Leave a Rating'}</span>
			<div className="d-flex align-items-center gap-2">
				<div>
					{Array.from(Array(5)).map((_, index) => (
						<Stars
							key={index}
							index={index}
							onClickHandler={() => {
								if (currentRatings === 0) {
									setNewStarRating(index + 1);
									setShowModal(true);
								}
							}}
							onMouseEnter={() => {
								if (!currentRatings) setHoveredRating(index + 1);
							}}
							onMouseLeave={() => {
								if (!currentRatings) setHoveredRating(null);
							}}
							ratings={currentRatings || hoveredRating}
						/>
					))}
				</div>
				{courseRatingStatus !== RatingStatusEnum.APPROVED && currentRatings > 0 && (
					<span
						role="button"
						className="g_reg color14"
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							setShowModal(true);
						}}>
						Edit
					</span>
				)}
			</div>
			{showModal && !disableModal && (
				<SubmitEditStarRatingsModal
					courseName={courseName}
					courseRatings={newStarRating}
					courseDescription={courseDescription}
					onSubmit={(ratings, description) => {
						onSubmit(ratings, description);
						setShowModal(false);
					}}
					onHide={() => {
						setShowModal(false);
					}}
				/>
			)}
		</div>
	);
};

export default SubmitStarRatings;
