import React, { useEffect, useMemo } from 'react';
import CoursePaymentHeaderWithLogo from 'components/_v2/PaymentDetails/CoursePaymentHeaderWithLogo';
import { Course } from 'components/_v2/Course/type';
import CourseMoneyBreakdown from 'components/_v2/PaymentDetails/CourseMoneyBreakdown';
import LoginNewUser from 'components/_v2/LoginSignupNewUser/LoginNewUser';
import { useNavigate, useParams } from 'react-router-dom';
import { useUserContext } from 'context/UserContext';
import { BASE_PAGE_URL, ENROLL_URL } from 'pages/routing/routePages';
import useCourses from 'hooks/useCourses';
import { useCourseContext } from 'context/CourseContext';
import { removeChatStickyFromUi, reShowChatStickyOnUi } from 'utils/helpers';

const PaymentDetailsNonLoggedUser = () => {
	const navigate = useNavigate();
	const { user } = useUserContext();
	const { courseSlug } = useParams();
	const { hasValueSet } = useCourseContext();
	const { getCourses, courses } = useCourses();

	useEffect(() => {
		removeChatStickyFromUi();
		return () => {
			reShowChatStickyOnUi();
		};
	}, []);

	useEffect(() => {
		if (!hasValueSet) {
			getCourses();
		}
	}, [hasValueSet]);

	useEffect(() => {
		if (user) {
			navigate(`${ENROLL_URL}/${courseSlug}`);
		}
	}, [user]);

	const course = useMemo(() => {
		if (courses.length) {
			const course = courses.find((course: Course) => course.slug === courseSlug);
			if (!course) {
				navigate(BASE_PAGE_URL);
			}
			return course;
		}
	}, [courses]);

	return (
		<section className="payment-detail-wrapper">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-md-8 col-12 mt-5">
						<div className="card row mx-md-0 cardMinHeight mx-3 px-2 pb-3">
							<CoursePaymentHeaderWithLogo />
							<div className="row">
								<div className="col-12 col-lg-6">
									<CourseMoneyBreakdown course={course} />
								</div>
								<div className="col-12 col-lg-6">
									<LoginNewUser course={course} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default PaymentDetailsNonLoggedUser;
