import React from 'react';
import { useQuizContext } from 'context/QuizContext';
import CurrentQuestionProgress from './CurrentQuestionProgress';
import FullScreenButton from './FullScreenButton';
import TimerComponent from './TimerComponent';
import { ButtonBorderType, ButtonType } from 'components/_v2/CommonComponents/types';
import { QuizObjectType, QuizQuestionObjectType } from './type';
import useCourses from 'hooks/useCourses';
import useQuiz from 'hooks/useQuiz';
import useIsSmallScreen from 'hooks/useIsSmallScreen';
import './OngoingQuizButtons.css';
import Button from 'components/_v2/CommonComponents/Button';

const VerticalLine = () => <div className="verticalSeparationLine" />;

const OngoingQuizButtons = ({
	handleFullScreen,
	isFullScreen = false,
	lessonQuiz,
	onTimeFinish,
	courseId,
	sectionId,
	lessonId,
	currentQuestion,
}: {
	isFullScreen?: boolean;
	handleFullScreen?: () => void;
	lessonQuiz: QuizObjectType;
	onTimeFinish: () => void;
	sectionId: string;
	courseId: string;
	lessonId: string;
	currentQuestion: QuizQuestionObjectType;
}) => {
	const { quiz, setCurrentQuestion, setAnswer } = useQuizContext();

	const { handleQuizSubmit } = useQuiz();
	const { getUserCourses } = useCourses();
	const finalQuestion = quiz.currentQuestion === lessonQuiz.questions.length;
	const isSmallScreen = useIsSmallScreen(576);

	const handleNextButtonClick = async () => {
		const doesAnswerExistForCurrentQuestion = quiz.answersProvided.find(
			(answer) => answer.questionId === currentQuestion.id,
		);
		if (!doesAnswerExistForCurrentQuestion) {
			setAnswer(currentQuestion.id, ['']);
		}
		if (!finalQuestion) {
			await handleQuizSubmit({
				courseId,
				sectionId,
				isSubmit: false,
				lessonId,
				quizId: lessonQuiz.id,
				questionAnswer: quiz.answersProvided,
				is_retake: quiz.isRetake,
			});
		}
		setCurrentQuestion(
			quiz.currentQuestion + 1,
			finalQuestion
				? {
						isSubmit: !!finalQuestion,
						quizId: lessonQuiz.id,
						sectionId,
						courseId,
						is_retake: quiz.isRetake,
				  }
				: undefined,
		);
		if (finalQuestion) {
			await getUserCourses();
		}
	};

	return (
		<div className={`${isSmallScreen ? '' : 'lectureButtons start-0'} w-100`}>
			<div className={`${isSmallScreen ? '' : 'dashedLine'} w-100`}>
				<div className="d-flex w-100 justify-content-between px-sm-0 mb-4 mt-3 px-4">
					{!isSmallScreen && (
						<div className="d-flex gap-3">
							{quiz.endTime && (
								<>
									<TimerComponent remainingTime={quiz.remainingTime} onTimeFinish={onTimeFinish} />
									<VerticalLine />
								</>
							)}
							<CurrentQuestionProgress
								questionNumber={quiz.currentQuestion}
								totalQuestions={lessonQuiz.questions.length}
							/>
							<VerticalLine />
							<FullScreenButton isFullScreen={isFullScreen} handleFullScreen={handleFullScreen} />
						</div>
					)}
					<div className="d-flex justify-content-between ongoingQuizButtons gap-3">
						{quiz.currentQuestion !== 1 ? (
							<Button
								buttonText="Previous Question"
								buttonType={ButtonType.GREY_BUTTON}
								classes="g_mid fs-12"
								buttonBorderType={ButtonBorderType.GREY_BORDER}
								handleClick={() => setCurrentQuestion(quiz.currentQuestion - 1)}
								px="20px"
								py="10px"
							/>
						) : (
							<div></div>
						)}
						<Button
							buttonText={finalQuestion ? 'Submit' : 'Next Question'}
							buttonType={ButtonType.PRIMARY_BUTTON}
							classes="g_mid fs-12"
							buttonBorderType={ButtonBorderType.BLACK_BORDER}
							handleClick={handleNextButtonClick}
							px="20px"
							py="10px"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OngoingQuizButtons;
