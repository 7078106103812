import React, { FC, useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import { gql, useMutation } from '@apollo/client';
import * as Yup from 'yup';
import clsx from 'clsx';
import { toast } from 'react-hot-toast';
import { VERIFYOTP } from './core/_requests';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { useUserContext } from 'context/UserContext';
import { AUTH_LOCAL_STORAGE_KEY, VERIFY_USER_STORAGE_KEY } from './core/AuthHelpers';
import Button from 'components/_v2/CommonComponents/Button';
import { ButtonBorderType, ButtonType } from 'components/_v2/CommonComponents/types';
import Loading from 'components/_v2/CommonComponents/Loading';
import { useAuthModalContext } from 'context/AuthContext';
import { RegistrationUserOutput } from '../../generated/types';

type Props = {
	userDetails: { email: string; name: string; password: string; mobileNo: string };
	handleResendOTP: ({
		email,
		password,
		mobileNumber,
		fullName,
		onCompleted,
		onError,
	}: {
		email: string;
		password: string;
		mobileNumber: string;
		fullName: string;
		onCompleted?: (data: { newStudentRegister: RegistrationUserOutput }) => void;
		onError?: () => void;
	}) => Promise<RegistrationUserOutput | undefined>;
};

const initialValues = {
	code: '',
};

const verificationSchema = Yup.object().shape({
	code: Yup.string().required('Please enter OTP'),
});

export const Verification: FC<Props> = ({ userDetails, handleResendOTP }) => {
	const { verification, toggleVerificationModal } = useAuthModalContext();
	const [loadingOTP, setLoadingOTP] = useState(false);
	const [verifyOtp] = useMutation(VERIFYOTP);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const [timer, setTimer] = useState(89);
	const [isResendEnabled, setIsResendEnabled] = useState(false);
	const { refetchLocalStorageTokenDetails } = useUserContext();

	useEffect(() => {
		if (verification && timer > 0) {
			const countdown = setTimeout(() => setTimer(timer - 1), 1000);
			return () => clearTimeout(countdown);
		} else {
			setIsResendEnabled(true);
		}
	}, [timer, verification]);

	const handleResendClick = async () => {
		setLoadingOTP(true);
		await handleResendOTP({
			email: userDetails.email,
			fullName: userDetails.name,
			mobileNumber: userDetails.mobileNo,
			password: userDetails.password,
			onCompleted: (data) => {
				localStorage.setItem(VERIFY_USER_STORAGE_KEY, JSON.stringify(data.newStudentRegister));
				setLoadingOTP(false);
				setTimer(89);
				setIsResendEnabled(false);
			},
			onError: () => {
				toast.error('There was an error in sending you OTP. Please Try Again!');
				setLoadingOTP(false);
				setTimer(5);
				setIsResendEnabled(false);
			},
		});
	};
	const formik = useFormik({
		initialValues,
		validationSchema: verificationSchema,
		onSubmit: async (values, { setSubmitting }) => {
			setErrorMessage(null);
			try {
				const verifyUserValue = JSON.parse(localStorage.getItem(VERIFY_USER_STORAGE_KEY));
				const { data } = await verifyOtp({
					variables: {
						studentRegisterVerificationInput: {
							otp: values.code,
						},
					},
					context: {
						headers: {
							authorization: verifyUserValue?.access_token,
						},
					},
				});

				if (data?.newStudentverification?.is_success) {
					toast.success('Email verification successful!');
					localStorage.setItem(
						AUTH_LOCAL_STORAGE_KEY,
						JSON.stringify(data?.newStudentverification),
					);
					localStorage.removeItem(VERIFY_USER_STORAGE_KEY);
					refetchLocalStorageTokenDetails();
					toggleVerificationModal();
					formik.resetForm();
				} else {
					setErrorMessage('Invalid OTP, Please Retry!!');
				}
			} catch (error) {
				console.error('Verification error:', error);
				localStorage.removeItem(VERIFY_USER_STORAGE_KEY);
				setErrorMessage('Verification failed. Please try again.');
			}

			setSubmitting(false);
		},
	});

	return (
		<div>
			<Modal
				centered
				show={verification}
				onHide={() => {
					toggleVerificationModal();
					formik.resetForm();
					localStorage.removeItem(VERIFY_USER_STORAGE_KEY);
				}}>
				<Modal.Header>
					<Modal.Title className="mx-auto text-center">
						<div>
							<img src={toAbsoluteUrl('/media/logo/veri.png')} alt="Verification" />
						</div>
						<h2 className="veri-title lora">Verification</h2>
						<p className="veri-label veri-otp-layout g_reg">
							Please enter the 6-digit code sent to <br />
							<span className="veri-label-strong">{userDetails.email}</span>
						</p>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="px-4">
					<form onSubmit={formik.handleSubmit}>
						<div className="row">
							<div className="col-12">
								<label className="label-style g_reg">
									OTP <span className="text-danger">*</span>
								</label>
								<input
									placeholder="OTP"
									autoComplete="off"
									{...formik.getFieldProps('code')}
									className={clsx('form-control input-inner-style g_reg', {
										'is-invalid': formik.touched.code && formik.errors.code,
									})}
								/>
								{formik.touched.code && formik.errors.code && (
									<div className="text-danger fs-7 mt-1">
										<i className="fa fa-exclamation-circle me-1"></i>
										{formik.errors.code}
									</div>
								)}
								{errorMessage && (
									<div className="text-danger fs-7 mt-1">
										<i className="fa fa-exclamation-circle me-1"></i>
										{errorMessage}
									</div>
								)}
								<p className="veri-desc mt-2 text-start">
									{isResendEnabled ? (
										<button
											type="button"
											className={`btn resend-btn border-0 ${loadingOTP ? 'disabled' : ''}`}
											onClick={handleResendClick}>
											Resend OTP
										</button>
									) : (
										<>
											Please wait &nbsp;
											<span className="veri-desc-strong">{timer}s</span>
											&nbsp; before requesting another code
										</>
									)}
								</p>
							</div>

							<div className="col-12 flex-column-reverse d-md-block d-flex mb-4 gap-4 text-center ">
								<div className="row">
									<div className="col-6">
										<Button
											buttonText="Cancel"
											buttonType={ButtonType.GREY_BUTTON}
											classes="g_mid fs-16 w-100"
											buttonBorderType={ButtonBorderType.GREY_BORDER}
											disabled={formik.isSubmitting}
											handleClick={() => {
												formik.resetForm();
												toggleVerificationModal();
												localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
											}}
										/>
									</div>
									<div className="col-6">
										<Button
											buttonText={
												<div className="d-flex justify-content-center align-items-center flex-row gap-2">
													{formik.isSubmitting && formik.isValid ? <Loading width={20} /> : <></>}
													Continue
												</div>
											}
											buttonType={ButtonType.PRIMARY_BUTTON}
											classes="g_mid fs-16 w-100"
											buttonBorderType={ButtonBorderType.BLACK_BORDER}
											disabled={formik.isSubmitting || !formik.isValid}
										/>
									</div>
								</div>
							</div>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</div>
	);
};
