import React from 'react';
import './InstructorProfile.css';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { Mentor } from 'components/_v2/Course/type';
import useIsSmallScreen from 'hooks/useIsSmallScreen';
import { Image } from 'react-bootstrap';

const MentorDetailsRenderer = ({ mentor }: { mentor: Mentor }) => {
	return (
		<div className="d-flex flex-column ms-2 justify-content-center gap-3">
			<div className="d-flex text-font-sizing gap-1">
				<Image src={toAbsoluteUrl('/media/instructor-images/instructor_rating.svg')} alt="rating" />
				{/* {mentor.userProfile?.[0]?.gender} */}
				4.7 Instructor Rating
			</div>
			<div className="d-flex text-font-sizing gap-1">
				<Image
					src={toAbsoluteUrl('/media/instructor-images/instructor_reviews.svg')}
					alt="rating"
				/>
				67,254 Reviews
			</div>
			<div className="d-flex text-font-sizing gap-1">
				<Image
					src={toAbsoluteUrl('/media/instructor-images/instructor_students.svg')}
					alt="rating"
				/>
				2,00,000 Students
			</div>
			<div className="d-flex text-font-sizing gap-1">
				<Image
					src={toAbsoluteUrl('/media/instructor-images/instructor_courses.svg')}
					alt="rating"
				/>
				7 Courses
			</div>
		</div>
	);
};

const InstructorProfile = ({
	instructors,
	fullWidth = false,
}: {
	instructors: { mentor: Mentor }[];
	fullWidth?: boolean;
}) => {
	const isSmallScreen = useIsSmallScreen();
	return (
		<section className={fullWidth ? '' : isSmallScreen ? 'pt-3' : 'px-3'}>
			<div className="container">
				<div className={`row ${fullWidth ? '' : 'px-lg-5 px-sm-3 px-2'}`}>
					<div className={`${fullWidth ? 'col-lg-12' : 'col-lg-7 col-12'}`}>
						<div className="row">
							<div className={fullWidth ? 'col-lg-12' : 'offset-lg-1 p-lg-0'}>
								<div>
									<h2 className="instructor-heading ibm_semi">Meet your Mentor</h2>
									<div className="col-12 ">
										{instructors.map((instructor) => {
											const mentorDetails = instructor.mentor;
											const mentorFullName = mentorDetails.fullName;
											return (
												<div
													className="mentorContainer mb-4 bg-white p-4"
													key={instructor.mentor.id}>
													<div className="col-sm-12 col-md-50 col-lg-25">
														<div className="d-flex mb-4 gap-1">
															<img
																src={toAbsoluteUrl(
																	mentorDetails.userProfile?.[0]?.imageUrl ??
																		'/media/instructor-images/instructor_profile.png',
																)}
																alt="Instructor_Image"
																className="instructor-profile-custom-style"
															/>
															<MentorDetailsRenderer mentor={mentorDetails} />
														</div>
														<div className="d-flex flex-column gap-3">
															<span className="g_mid fs-18">
																<u>{mentorFullName}</u>
															</span>
															<span className="g_reg fs-15">
																{mentorDetails.userProfile?.[0]?.qualification}
															</span>
															<p className="instructor-full-desc g_reg text-justify">
																{mentorDetails.userProfile?.[0]?.description}
															</p>
														</div>
													</div>
												</div>
											);
										})}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default InstructorProfile;
