import React from 'react';
import './CourseFAQS.css';
import { FaqsType } from 'components/_v2/CourseDetail/CourseFAQS/types';
import { Accordion } from 'react-bootstrap';

const CourseFAQS = ({
	faqsData,
	fullWidth,
	removeSpacing = false,
}: {
	faqsData: FaqsType[];
	fullWidth?: boolean;
	removeSpacing?: boolean;
}) => {
	return (
		<section className={`${removeSpacing ? 'mb-4' : 'course-faqs-wrapper'} px-3`}>
			<div className="container">
				<div className={`row ${fullWidth ? '' : 'px-lg-5 px-sm-3 px-2'}`}>
					<div className={`${fullWidth ? 'col-lg-12' : 'col-lg-7 col-12'}`}>
						<div className="row">
							<div className={`${fullWidth ? '' : 'offset-lg-1'} p-lg-0`}>
								<h2 className="faqs-heading lora">FAQ’s</h2>

								<div>
									<div className="accordion" id="accordionFAQExample">
										<Accordion className="faq">
											{faqsData.map((faq) => (
												<Accordion.Item
													eventKey={faq.id}
													key={faq.id}
													className="border-style-ac my-3">
													<Accordion.Header className="p_reg faq-btn">
														{faq.question}
													</Accordion.Header>
													<Accordion.Body className={`faq-answers p_reg pt-0 pb-4`}>
														{faq.answer}
													</Accordion.Body>
												</Accordion.Item>
											))}
										</Accordion>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default CourseFAQS;
