import React, { createContext, useState, useContext, ReactNode } from 'react';
import { CountryObjectType } from 'generated/types';

interface DataArrayType {
	value: string;
	label: string;
}
// Define the shape of the context state
interface UserAddressState {
	countries: DataArrayType[];
	setCountries: React.Dispatch<React.SetStateAction<DataArrayType[]>>;
	allCountryData: CountryObjectType[];
	setAllCountryData: React.Dispatch<React.SetStateAction<CountryObjectType[]>>;
	states: DataArrayType[];
	setStates: React.Dispatch<React.SetStateAction<DataArrayType[]>>;
	cities: DataArrayType[];
	setCities: React.Dispatch<React.SetStateAction<DataArrayType[]>>;
	selectedCountryId: string;
	setSelectedCountryId: React.Dispatch<React.SetStateAction<string>>;
	selectedStateId: string;
	setSelectedStateId: React.Dispatch<React.SetStateAction<string>>;
	selectedCityId: string;
	setSelectedCityId: React.Dispatch<React.SetStateAction<string>>;
}

// Create the context with a default value
const UserAddress = createContext<UserAddressState | undefined>(undefined);

// Create a provider component
export const UserAddressProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [allCountryData, setAllCountryData] = useState<CountryObjectType[]>([]);
	const [countries, setCountries] = useState<DataArrayType[]>([]);
	const [states, setStates] = useState<DataArrayType[]>([]);
	const [cities, setCities] = useState<DataArrayType[]>([]);
	const [selectedCountryId, setSelectedCountryId] = useState<string>('');
	const [selectedStateId, setSelectedStateId] = useState<string>('');
	const [selectedCityId, setSelectedCityId] = useState<string>('');

	return (
		<UserAddress.Provider
			value={{
				countries,
				setCountries,
				states,
				setStates,
				cities,
				setCities,
				selectedCountryId,
				setSelectedCountryId,
				selectedStateId,
				setSelectedStateId,
				selectedCityId,
				setSelectedCityId,
				allCountryData,
				setAllCountryData,
			}}>
			{children}
		</UserAddress.Provider>
	);
};

// Create a custom hook to use the context
export const useUserAddressContext = (): UserAddressState => {
	const context = useContext(UserAddress);
	if (!context) {
		throw new Error('useUserAddress must be used within a UserAddressProvider');
	}
	return context;
};
