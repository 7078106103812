import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import moment from 'moment';
import { Image } from 'react-bootstrap';
import { LessonType } from '../Course/type';
import useIsSmallScreen from '../../../hooks/useIsSmallScreen';
import Loading from 'components/_v2/CommonComponents/Loading';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useCourses from '../../../hooks/useCourses';
import Button from 'components/_v2/CommonComponents/Button';
import { ButtonBorderType, ButtonType } from 'components/_v2/CommonComponents/types';
import { BASE_PAGE_URL, MY_COURSE_URL } from '../../../pages/routing/routePages';

const CourseCompleted = ({
	lessonData,
	courseId,
	hasCertificate,
}: {
	lessonData: LessonType;
	courseId: string;
	hasCertificate: boolean;
}) => {
	const isSmallScreen = useIsSmallScreen(576);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const { downloadCertificateForCourse } = useCourses();
	return (
		<div className="d-flex flex-column align-items-center gap-1">
			<Image
				src={toAbsoluteUrl('/media/course-detail-images/course_completed.svg')}
				height={246}
				width={147}
			/>
			<p className="lora completedHeading">Congratulations!</p>
			<p className="g_light d-flex align-items-center flex-column">
				You have successfully completed this course
				<p className="g_light">
					on <span>{moment(lessonData.startDate).format('Do MMMM, YYYY')}</span>
				</p>
			</p>
			{isSmallScreen && (
				<div className="d-flex gap-2 px-1 pb-4">
					<Button
						buttonText="Go Back to My Courses"
						classes="g_mid"
						buttonType={ButtonType.GREY_BUTTON}
						buttonBorderType={ButtonBorderType.GREY_BORDER}
						handleClick={() => navigate(MY_COURSE_URL)}
						px="10px"
						py="10px"
					/>

					<Button
						buttonText={
							<div className="d-flex align-items-center justify-content-center gap-2">
								{loading && <Loading width="50px" height="25px" />}
								{hasCertificate ? 'Download Certificate' : 'Back to Home'}
							</div>
						}
						disabled={loading}
						classes="g_mid"
						buttonType={ButtonType.PRIMARY_BUTTON}
						buttonBorderType={ButtonBorderType.BLACK_BORDER}
						px="12px"
						py="10px"
						handleClick={() => {
							if (hasCertificate) {
								setLoading(true);
								downloadCertificateForCourse({
									courseId,
									onCompletedCallback: () => setLoading(false),
									onErrorCallback: () => setLoading(false),
								});
							} else {
								navigate(BASE_PAGE_URL);
							}
						}}
					/>
				</div>
			)}
		</div>
	);
};

export default CourseCompleted;
