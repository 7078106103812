import React from 'react';
import './loginbutton.css';
import Button from '../CommonComponents/Button';
import { ButtonBorderType, ButtonType } from '../CommonComponents/types';
import { useAuthModalContext } from 'context/AuthContext';

const LoginButton = () => {
	const { toggleLoginModal } = useAuthModalContext();
	return (
		<>
			<Button
				handleClick={toggleLoginModal}
				classes={`g_mid mx-3`}
				buttonText="Log In"
				buttonType={ButtonType.WHITE_BUTTON}
				buttonBorderType={ButtonBorderType.BLACK_BORDER}
			/>
		</>
	);
};

export default LoginButton;
