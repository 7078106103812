import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const CoursePaymentHeaderWithLogo = () => {
	const navigate = useNavigate();
	return (
		<div className="d-flex justify-content-between align-items-center p-3">
			<div>
				<img
					src={toAbsoluteUrl('/media/logo/leftarrow.png')}
					alt="Left"
					onClick={() => navigate(-1)}
					className=""
				/>
			</div>
			<div>
				<img
					src={toAbsoluteUrl('/media/logo/hoGrowthBlack.svg')}
					alt="Logo"
					className="logo-layout"
				/>
			</div>
			<div /> {/*	To align logo in center */}
		</div>
	);
};

export default CoursePaymentHeaderWithLogo;
