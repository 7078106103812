import { gql } from '@apollo/client';
import { graphql } from 'babel-plugin-relay/macro';

export const GETCART = gql`
	query requests_getCartQuery {
		getCart {
			id
			userId
			courseId
			course {
				id
				name
				slug
				shortDescription
				description
				sortOrder
				status
				amount
				sellPrice
				startDatetime
				endDatetime
				totalSeat
				costType
				address
				meetingLink
				meetingNumber
				meetingPassword
				hasCertificate
				hasQuiz
				requirements
				courseCategoryId
				courseCategory {
					id
					name
				}
				courseType {
					id
					name
					slug
				}
			}
			price
			noOfSeat
			totalPrice
			taxRate
			taxAmount
		}
	}
`;
export const REMOVEFROMCART = gql`
	mutation requests_removeFromCartMutation($cartId: String!) {
		removeFromCart(CartId: $cartId)
	}
`;

export const CREATE_ORDER_MUTATION = gql`
	mutation createOrder($createOrderInputData: CreateOrderInput!) {
		createOrder(createOrderInput: $createOrderInputData) {
			accept_partial
			amount
			amount_paid
			callback_method
			callback_url
			cancelled_at
			created_at
			currency
			customer {
				contact
				email
				name
			}
			description
			expire_by
			expired_at
			first_min_partial_amount
			id
			notes {
				policy_name
			}
			notify {
				email
				sms
				whatsapp
			}
			payments {
				amount
				created_at
				method
				payment_id
				plink_id
				status
				updated_at
			}
			reference_id
			reminder_enable
			reminders {
				status
			}
			short_url
			status
			updated_at
			upi_link
			user_id
			whatsapp_link
		}
	}
`;

export const ENROLL_FREE_COURSE = gql`
	mutation enrollFreeCourse($createFreeCourseEnrollmentInput: CreateFreeCourseEnrollmentInput!) {
		enrollFreeCourse(createFreeCourseEnrollmentInput: $createFreeCourseEnrollmentInput)
	}
`;

export const CREATE_ORDER_AND_USER_MUTATION = gql`
	mutation createOrderNewUser($createOrderNewUser: CreateOrderNewUserInput!) {
		createOrderNewUser(CreateOrderNewUserInput: $createOrderNewUser) {
			accept_partial
			amount
			amount_paid
			callback_method
			callback_url
			cancelled_at
			created_at
			currency
			customer {
				contact
				email
				name
			}
			description
			expire_by
			expired_at
			first_min_partial_amount
			id
			notes {
				policy_name
			}
			notify {
				email
				sms
				whatsapp
			}
			payments {
				amount
				method
				payment_id
				plink_id
				status
			}
			reference_id
			reminder_enable
			reminders {
				status
			}
			short_url
			status
			updated_at
			upi_link
			user_id
			whatsapp_link
		}
	}
`;

export const VERIFY_ORDER_MUTATION = gql`
	mutation verifyOrder($verifyOrderInputData: VerifyOrderInput!) {
		verifyOrder(verifyOrderInput: $verifyOrderInputData) {
			id
			userId
			subTotal
			total
			tax
			gstNo
			status
			transactionId
			paymentGatewayId
			paymentGatewayMode
			transactionStatus
			transactionDateTime
			totalDiscount
			type
			createdAt
			paymentLink
			orderDetails {
				id
				orderId
				courseId
				noOfSeat
				price
				totalPrice
				tax
				couponDiscountAmount
				taxAmount
				subTotal
				discountValue
				createdAt
				course {
					id
					name
				}
			}
			address_id
		}
	}
`;

export const VERIFY_ORDER_ADMIN_MUTATION = gql`
	mutation verifyOrderAdmin($verifyOrderInputData: VerifyOrderInput!) {
		verifyOrderAdmin(verifyOrderInput: $verifyOrderInputData) {
			id
			userId
			subTotal
			total
			tax
			gstNo
			status
			transactionId
			paymentGatewayId
			paymentGatewayMode
			transactionStatus
			transactionDateTime
			totalDiscount
			type
			createdAt
			deletedAt
			platform
			paymentLink
			invoiceStatus
			orderDetails {
				id
				orderId
				courseId
				noOfSeat
				price
				totalPrice
				tax
				couponDiscountAmount
				taxAmount
				subTotal
				discountValue
				createdAt
			}
			address_id
			companyName
			registerAddress
		}
	}
`;

export const VERIFY_ORDER_NEW_USER = gql`
	mutation verifyOrderNewUser($verifyOrderInputData: VerifyOrderInput!) {
		verifyOrderNewUser(verifyOrderInput: $verifyOrderInputData) {
			access_token
			fullName
			id
			subTotal
			total
			tax
			gstNo
			status
			transactionId
			paymentGatewayId
			paymentGatewayMode
			transactionStatus
			transactionDateTime
			totalDiscount
			type
			createdAt
			deletedAt
			platform
			paymentLink
			invoiceStatus
			orderDetails {
				id
				orderId
				courseId
				noOfSeat
				price
				totalPrice
				tax
				couponDiscountAmount
				taxAmount
				subTotal
				discountValue
				createdAt
			}
			companyName
			registerAddress
		}
	}
`;

export const DOWNLOADINVOICE = gql`
	query GetInvoice($order_id: String!) {
		downloadInvoice(order_id: $order_id)
	}
`;

export const DOWNLOADCERTIFICATE = gql`
	query GetCertificate($course_id: String!) {
		downloadCertificate(course_id: $course_id)
	}
`;
