import { Image } from 'react-bootstrap';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import Button from 'components/_v2/CommonComponents/Button';
import { ButtonBorderType, ButtonType } from 'components/_v2/CommonComponents/types';
import { useNavigate } from 'react-router-dom';
import { BASE_PAGE_URL } from 'pages/routing/routePages';
import './InternalServerError.css';

const InternalServerError = () => {
	const navigate = useNavigate();
	return (
		<div
			className="d-flex justify-content-center align-items-center flex-column"
			style={{ height: '100vh' }}>
			<Image
				src={toAbsoluteUrl('/media/nodata/500.svg')}
				alt="No data"
				style={{ maxWidth: '90vw' }}
			/>
			<div className="d-flex flex-column align-items-center customWidth gap-2 pb-5 text-justify">
				<span className="g_bold fs-24">Internal Server Error!</span>
				<span className="g_mid fs-16 color17">
					An Internal Server error - Error 500 - has occurred on our end, please standby as we fix
					it.
				</span>
			</div>
			<Button
				buttonText="Back to Home"
				classes="g_mid fs-16"
				buttonType={ButtonType.PRIMARY_BUTTON}
				buttonBorderType={ButtonBorderType.BLACK_BORDER}
				handleClick={() => navigate(BASE_PAGE_URL)}
				type="button"
			/>
		</div>
	);
};

export default InternalServerError;
