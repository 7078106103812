import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import {
	PiNumberCircleOneFill,
	PiNumberCircleTwoFill,
	PiNumberCircleThreeFill,
	PiNumberCircleFourFill,
} from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';
import { Login } from '../../../pages/auth/Login';
import { Register } from '../../../pages/auth/Register';
import { AiFillCheckCircle } from 'react-icons/ai';
import { useAuth } from '../../../pages/auth/core/Auth';
import { getUserData } from '../../../utils/getUserData';
import { useMutation, useQuery } from '@apollo/client';
import { GETMYPROFILE } from '../../Profile-page/core/requests';
import { CREATE_ORDER_MUTATION, GETCART } from '../core/requests';
import toast from 'react-hot-toast';
import Loader from '../../common/Spinner/Spinner';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import clsx from 'clsx';
const validationSchema = Yup.object({
	firstName: Yup.string().required('First Name is required'),
	lastName: Yup.string().required('Last Name is required'),
	address: Yup.string().required('Address is required'),
	city: Yup.string().required('City is required'),
	state: Yup.string().required('State is required'),
	zipCode: Yup.string().required('Zip Code is required'),
});

export const Checkout = (props: any) => {
	const [currentAuthToken, setCurrentAuthToken] = React.useState(getUserData().authToken);
	const [billingSuccess, setBillingSuccess] = useState(false);
	const [gstSuccess, setgstSuccess] = useState(false);
	const { data: profile, refetch } = useQuery(GETMYPROFILE, {
		onCompleted: () => {
			refetch();
		},
	});
	const { showLoader, setShowLoader, formSubmitted, setFormSubmitted } = props;

	const [createOrder, { data, loading }] = useMutation(CREATE_ORDER_MUTATION);
	const { data: cartDataRecord, refetch: cartrefetch } = useQuery(GETCART, {
		onCompleted: () => {
			cartrefetch();
		},
	});
	const cartData = cartDataRecord?.getCart ?? [];
	const calculateTotalPrice = () => {
		const total = cartData.reduce(
			(acc: any, item: any) => {
				return {
					totalPrice: acc.totalPrice + item.totalPrice,
					totalTaxAmount: acc.totalTaxAmount + item.taxAmount,
				};
			},
			{ totalPrice: 0, totalTaxAmount: 0 },
		);

		return total;
	};

	const total = calculateTotalPrice();
	const billingPass: MutableRefObject<string> = useRef<string>('FALSE');
	const gstPass: MutableRefObject<string> = useRef<string>('FALSE');
	const initialValues = {
		firstName: profile?.getMyProfile?.firstName || '',
		lastName: profile?.getMyProfile?.lastName || '',
		city: (profile?.getMyProfile?.userAddress && profile?.getMyProfile?.userAddress[0]?.city) || '',
		state:
			(profile?.getMyProfile?.userAddress && profile?.getMyProfile?.userAddress[0]?.state) || '',
		zipCode:
			(profile?.getMyProfile?.userAddress && profile?.getMyProfile?.userAddress[0]?.zipCode) || '',
		address:
			(profile?.getMyProfile?.userAddress && profile?.getMyProfile?.userAddress[0]?.address) || '',
		companyName: '',
		registerAddress: '',
		cartitems:
			cartData.map((cartItem: any) => {
				return {
					tax: cartItem.taxRate,
					totalPrice: cartItem.totalPrice,
					taxAmount: cartItem.taxAmount,
					subTotal: cartItem.price,
					courseId: cartItem.course.id,
					noOfSeat: cartItem.noOfSeat,
					price: cartItem.price,
					discountValue: 0,
					couponDiscountAmount: 0,
				};
			}) || [],
		gstNo: '',
	};
	const [formData, setformData] = useState({
		firstName: profile?.getMyProfile?.firstName || '',
		lastName: profile?.getMyProfile?.lastName || '',
		city:
			(profile &&
				profile?.getMyProfile &&
				profile?.getMyProfile?.address &&
				profile?.getMyProfile?.address[0] &&
				profile?.getMyProfile?.address[0]?.city) ||
			'',
		state:
			(profile &&
				profile?.getMyProfile &&
				profile?.getMyProfile?.address &&
				profile?.getMyProfile?.address[0] &&
				profile?.getMyProfile?.address[0]?.state) ||
			'',
		zipCode:
			profile &&
			profile?.getMyProfile &&
			profile?.getMyProfile?.address &&
			profile?.getMyProfile?.address[0] &&
			profile?.getMyProfile?.address[0]?.zipCode,
		address:
			(profile?.getMyProfile?.userAddress && profile?.getMyProfile?.userAddress[0]?.address) || '',
		companyName: '',
		registerAddress: '',
		cartitems:
			cartData.map((cartItem: any) => {
				return {
					tax: cartItem.taxRate,
					totalPrice: cartItem.totalPrice,
					taxAmount: cartItem.taxAmount,
					subTotal: cartItem.price,
					courseId: cartItem.course.id,
					noOfSeat: cartItem.noOfSeat,
					price: cartItem.price,
					discountValue: 0,
					couponDiscountAmount: 0,
				};
			}) || [],
		gstNo: '',
	});

	useEffect(() => {
		const updatedFormData = {
			firstName: profile?.getMyProfile?.firstName || '',
			lastName: profile?.getMyProfile?.lastName || '',
			city:
				(profile?.getMyProfile?.userAddress && profile?.getMyProfile?.userAddress[0]?.city) || '',
			state:
				(profile?.getMyProfile?.userAddress && profile?.getMyProfile?.userAddress[0]?.state) || '',
			zipCode:
				(profile?.getMyProfile?.userAddress && profile?.getMyProfile?.userAddress[0]?.zipCode) ||
				'',
			address:
				(profile?.getMyProfile?.userAddress && profile?.getMyProfile?.userAddress[0]?.address) ||
				'',
			companyName: '',
			registerAddress: '',
			cartitems:
				cartData.map((cartItem: any) => {
					return {
						tax: cartItem.taxRate,
						totalPrice: cartItem.totalPrice,
						taxAmount: cartItem.taxAmount,
						subTotal: cartItem.price,
						courseId: cartItem.course.id,
						noOfSeat: cartItem.noOfSeat,
						price: cartItem.price,
						discountValue: 0,
						couponDiscountAmount: 0,
					};
				}) || [],
			gstNo: '',
		};
		if (updatedFormData) {
			setformData(updatedFormData);
		}
	}, [cartData, profile, total.totalPrice]);
	const handleFormDataChange = (event: any) => {
		const { name, value } = event.target;
		setformData((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};
	const [open, setOpen] = useState(false);
	const [openRegister, setOpenRegister] = useState(false);
	const handleRegisterClose = () => setOpenRegister(false);
	const [datacart, setdata] = useState([]);
	const toggleLoginOpen = () => {
		setOpen(true);
		setOpenRegister(false);
	};

	const toggleRegisterOpen = () => {
		setOpenRegister(true);
		setOpen(false);
	};
	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: validationSchema,
		onSubmit: (values) => {
			if (formik.isValid) {
				setShowLoader(true);
				createOrder({
					variables: {
						createOrderInputData: values,
					},
					onCompleted: (data: any) => {
						toast.success('Successfully created Order', {
							style: {
								padding: '16px',
							},
						});
						window.location.href = data.createOrder.short_url;
						setFormSubmitted(true);
						setShowLoader(false);
					},
					onError: (err: any) => {
						toast.error(err.message);
					},
				});
			}
		},
	});

	useEffect(() => {
		if (profile && profile?.getMyProfile) {
			cartrefetch();
			formik.setFieldValue('firstName', profile.getMyProfile.firstName);
			formik.setFieldValue('lastName', profile.getMyProfile.lastName);
			formik.setFieldValue(
				'address',
				profile &&
					profile?.getMyProfile &&
					profile?.getMyProfile?.userAddress &&
					profile?.getMyProfile?.userAddress[0] &&
					profile?.getMyProfile?.userAddress[0]?.address,
			);
			formik.setFieldValue(
				'city',
				profile?.getMyProfile?.userAddress && profile?.getMyProfile?.userAddress[0]?.city,
			);
			formik.setFieldValue(
				'state',
				profile?.getMyProfile?.userAddress && profile?.getMyProfile?.userAddress[0]?.state,
			);
			formik.setFieldValue(
				'zipCode',
				profile?.getMyProfile?.userAddress && profile?.getMyProfile?.userAddress[0]?.zipCode,
			);
			formik.setFieldValue(
				'cartitems',
				cartData.map((cartItem: any) => {
					return {
						tax: cartItem.taxRate,
						totalPrice: cartItem.totalPrice,
						taxAmount: cartItem.taxAmount,
						subTotal: cartItem.price,
						courseId: cartItem.course.id,
						noOfSeat: cartItem.noOfSeat,
						price: cartItem.price,
						discountValue: 0,
						couponDiscountAmount: 0,
					};
				}) || [],
			);
		}
	}, [profile, cartData]);
	React.useEffect(() => {
		const handleStorageChange = () => {
			setCurrentAuthToken(getUserData().authToken);
		};

		window.addEventListener('storage', handleStorageChange);
		return () => {
			window.removeEventListener('storage', handleStorageChange);
		};
	}, []);

	React.useEffect(() => {
		const intervalId = setInterval(() => {
			setCurrentAuthToken(getUserData().authToken);
		}, 500);
		return () => clearInterval(intervalId);
	}, []);
	const handleBillingSubmit = () => {
		setBillingSuccess(true);
		billingPass.current = 'FALSE';
	};
	const [showPayment, setShowPayment] = useState(false);
	const [isGstOpened, setIsGstOpened] = useState(true);

	const handlegstSubmit = () => {
		setgstSuccess(true);
		// gstPass.current = "TRUE";
		// setShowPayment(true);
	};

	const handleSkip = () => {
		setIsGstOpened(false);
		setShowPayment(true);
	};
	return (
		<>
			{formSubmitted || showLoader ? (
				<Loader />
			) : (
				<>
					<section className="section">
						<div className="accordion checkout-accordion" id="accordionExample">
							<div className="accordion-item border-1 border-dark mb-2 border">
								<h2 className="accordion-header font-subtitle bg-light" id="headingLoginReg">
									<button
										className="accordion-button font-subtitle bg-light d-flex align-items-center"
										type="button"
										{...(!currentAuthToken && {
											'data-bs-toggle': 'collapse',
											'data-bs-target': '#collapseLoginReg',
											'aria-expanded': 'true',
											'aria-controls': 'collapseLoginReg',
										})}>
										{currentAuthToken !== null ? (
											<AiFillCheckCircle className="text-success me-2 fs-5 " />
										) : (
											<PiNumberCircleOneFill className="checkout-number fs-5 me-2" />
										)}
										Log In / Registration
									</button>
								</h2>
								{!currentAuthToken && (
									<>
										<div
											id="collapseLoginReg"
											className="accordion-collapse show collapse"
											aria-labelledby="headingLoginReg"
											data-bs-parent="#accordionExample">
											<div className="accordion-body">
												<div className="fs-6 text-dark">
													Enter your details or continue with Google
												</div>
												<button
													type="button"
													onClick={() => setOpen(true)}
													className="btn btn-primary fs-6 my-3 py-1 px-3">
													Log In
												</button>
												<div className="font-subtitle fs-6 text-muted">
													Don't have an account?
													<span
														onClick={() => setOpenRegister(true)}
														className="text-primary ms-1 fw-bold cursor-pointer">
														Sign up
													</span>
												</div>
											</div>
										</div>
									</>
								)}
							</div>
							<form onSubmit={formik.handleSubmit}>
								<>
									<div className="accordion-item border-1 border-dark mb-2 border">
										<h2 className="accordion-header font-subtitle bg-light" id="headingBilling">
											<button
												className={`accordion-button font-subtitle bg-light d-flex align-items-center justify-content-between ${
													currentAuthToken !== null && 'collapsed'
												}`}
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#collapseBilling"
												aria-expanded="true"
												aria-controls="collapseBilling">
												<div className="a-titles">
													{/* {billingPass.current === "TRUE" ? (<AiFillCheckCircle className="text-success me-2 fs-5 " />) : (<PiNumberCircleTwoFill className="checkout-number fs-5 me-2 mb-1" />)} */}
													{billingSuccess ? (
														<AiFillCheckCircle className="text-success me-2 fs-5 " />
													) : (
														<PiNumberCircleTwoFill className="checkout-number fs-5 me-2 mb-1" />
													)}
													{/* {billingPass.current === "FALSE" && <PiNumberCircleTwoFill className="checkout-number fs-5 me-2 mb-1" />} */}
													<span>Billing Address</span>
												</div>
												<div className="a-button">
													<div className="text-primary">Edit</div>
												</div>
											</button>
										</h2>
										<div
											id="collapseBilling"
											className={`accordion-collapse collapse ${
												currentAuthToken !== null && billingPass.current === 'TRUE' && 'show'
											}`}
											aria-labelledby="headingBilling"
											data-bs-parent="#accordionExample">
											<div className="accordion-body py-2">
												<div className="row gy-3">
													<div className="col-6 ">
														<label className="font-subtitle text-muted fs-6 mb-1">
															First Name
															<span className="required text-danger">*</span>
														</label>
														<input
															placeholder="Enter Your FirstName"
															{...formik.getFieldProps('firstName')}
															className={clsx('form-control border-muted', {
																'is-invalid': formik.touched.firstName && formik.errors.firstName,
															})}
															type="text"
															name="firstName"
														/>
														{formik.touched.firstName && formik.errors.firstName && (
															<div className="text-danger fs-7 mt-1">
																<i className="fa fa-exclamation-circle me-1"></i>
																<>{formik.errors.firstName}</>
															</div>
														)}
														{/* <input type="text" className="form-control border-muted" placeholder="Enter Your FirstName" name="firstName" value={formData.firstName} onChange={handleFormDataChange} /> */}
													</div>
													<div className="col-6">
														<label className="font-subtitle text-muted fs-6 mb-1">
															Last Name <span className="required text-danger">*</span>
														</label>
														<input
															placeholder="Enter Your lastName"
															{...formik.getFieldProps('lastName')}
															className={clsx('form-control border-muted', {
																'is-invalid': formik.touched.lastName && formik.errors.lastName,
															})}
															type="text"
															name="lastName"
														/>
														{formik.touched.lastName && formik.errors.lastName && (
															<div className="text-danger fs-7 mt-1">
																<i className="fa fa-exclamation-circle me-1"></i>
																<>{formik.errors.lastName}</>
															</div>
														)}
														{/* <input type="text" className="form-control border-muted" placeholder="Enter Your LastName" name="lastName" value={formData.lastName} onChange={handleFormDataChange} /> */}
													</div>
													<div className="col-12 mt-3">
														<label className="font-subtitle text-muted fs-6 mb-1">
															Address <span className="required text-danger">*</span>
														</label>
														<input
															placeholder="Enter Your address"
															{...formik.getFieldProps('address')}
															className={clsx('form-control border-muted', {
																'is-invalid': formik.touched.address && formik.errors.address,
															})}
															type="text"
															name="address"
														/>
														{formik.touched.address && formik.errors.address && (
															<div className="text-danger fs-7 mt-1">
																<i className="fa fa-exclamation-circle me-1"></i>
																<>{formik.errors.address}</>
															</div>
														)}
														{/* <input
                        type="text"
                        className="form-control border-muted"
                        placeholder="Enter Your Address"
                        name="address" value={formData.address} onChange={handleFormDataChange}
                      /> */}
													</div>
													<div className="col-4 mt-3">
														<label className="text-muted fs-6 mb-1">
															City
															<span className="required text-danger">*</span>
														</label>
														<input
															placeholder="Enter Your city"
															{...formik.getFieldProps('city')}
															className={clsx('form-control border-muted', {
																'is-invalid': formik.touched.city && formik.errors.city,
															})}
															type="text"
															name="city"
														/>
														{formik.touched.city && formik.errors.city && (
															<div className="text-danger fs-7 mt-1">
																<i className="fa fa-exclamation-circle me-1"></i>
																<>{formik.errors.city}</>
															</div>
														)}
														{/* <input
                        type="text"
                        className="form-control border-muted"
                        placeholder="Enter Your City"
                        name="city" value={formData.city} onChange={handleFormDataChange}
                      /> */}
													</div>
													<div className="col-4 mt-3">
														<label className="text-muted fs-6 mb-1">
															State
															<span className="required text-danger">*</span>
														</label>
														<input
															placeholder="Enter Your state"
															{...formik.getFieldProps('state')}
															className={clsx('form-control border-muted', {
																'is-invalid': formik.touched.state && formik.errors.state,
															})}
															type="text"
															name="state"
														/>
														{formik.touched.state && formik.errors.state && (
															<div className="text-danger fs-7 mt-1">
																<i className="fa fa-exclamation-circle me-1"></i>
																<>{formik.errors.state}</>
															</div>
														)}
														{/* <input
                        type="text"
                        className="form-control border-muted"
                        placeholder="Enter Your City"
                        name="state" value={formData.state} onChange={handleFormDataChange}
                      /> */}
													</div>
													<div className="col-4 mt-3">
														<label className="text-muted fs-6 mb-1">
															Zip Code
															<span className="required text-danger">*</span>
														</label>
														<input
															placeholder="Enter Your zipCode"
															{...formik.getFieldProps('zipCode')}
															className={clsx('form-control border-muted', {
																'is-invalid': formik.touched.zipCode && formik.errors.zipCode,
															})}
															type="text"
															name="zipCode"
														/>
														{formik.touched.zipCode && formik.errors.zipCode && (
															<div className="text-danger fs-7 mt-1">
																<i className="fa fa-exclamation-circle me-1"></i>
																<>{formik.errors.zipCode}</>
															</div>
														)}
														{/* <input
                        type="text"
                        className="form-control border-muted"
                        placeholder="Enter Zip Code"
                        name="zipCode" value={formData.zipCode} onChange={handleFormDataChange}
                      /> */}
													</div>
													<div className="col-12 mt-3">
														<button
															type="button"
															className="btn btn-primary"
															onClick={() => {
																handleBillingSubmit();
																// gstPass.current = "TRUE";
															}}
															data-bs-toggle="collapse"
															disabled={
																!formik.values.firstName ||
																!formik.values.lastName ||
																!formik.values.address ||
																!formik.values.zipCode ||
																!formik.values.state ||
																!formik.values.city
															}
															data-bs-target="#collapseGst"
															aria-expanded="true"
															aria-controls="collapseGst">
															Continue
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="accordion-item border-1 border-dark mb-2 border">
										<h2 className="accordion-header font-subtitle bg-light" id="headingGst">
											<button
												className="accordion-button font-subtitle bg-light d-flex align-items-center justify-content-between"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#collapseGst"
												aria-expanded="true"
												aria-controls="collapseGst">
												<div className="a-titles">
													{/* {gstPass.current === "TRUE" ? (<AiFillCheckCircle className="text-success me-2 fs-5 " />) : ()} */}
													{gstSuccess ? (
														<AiFillCheckCircle className="text-success me-2 fs-5 " />
													) : (
														<PiNumberCircleThreeFill className="checkout-number fs-5 me-2 mb-1" />
													)}
													{/* {gstPass.current === "TRUE" && <PiNumberCircleThreeFill className="checkout-number fs-5 me-2 mb-1" />} */}
													<span>GST Details (Optional)</span>
												</div>
												<div
													className="a-button"
													onClick={() => {
														handleSkip();
													}}
													data-bs-toggle="collapse"
													data-bs-target="#collapsePayment"
													aria-expanded="true"
													aria-controls="collapsePayment">
													<div className="text-primary">Skip</div>
												</div>
											</button>
										</h2>
										<div
											id="collapseGst"
											className={`accordion-collapse collapse`}
											aria-labelledby="headingGst"
											data-bs-parent="#accordionExample">
											<div className="accordion-body py-2">
												<div className="row">
													<div className="col-6">
														<label className="font-subtitle text-muted fs-6 mb-1">GST Number</label>
														<input
															placeholder="Enter Your gstNo"
															{...formik.getFieldProps('gstNo')}
															className={clsx('form-control border-muted')}
															type="text"
															name="gstNo"
														/>
														{/* <input type="text" className="form-control border-muted" placeholder="Enter GST Number" name="gstNo" defaultValue={formData.gstNo} onChange={handleFormDataChange} /> */}
													</div>
													<div className="col-6">
														<label className="font-subtitletext-muted fs-6 mb-1">
															Company Name
														</label>
														<input
															placeholder="Enter Your companyName"
															{...formik.getFieldProps('companyName')}
															className={clsx('form-control border-muted')}
															type="text"
															name="companyName"
														/>
														{/* <input type="text" className="form-control border-muted" placeholder="Enter Company Name" name="companyName" defaultValue={formData.companyName} onChange={handleFormDataChange} /> */}
													</div>
													<div className="col-12 mt-3">
														<label className="font-subtitle text-muted fs-6 mb-1">
															Registered Address
														</label>
														{/* <input
                        type="text"
                        className="form-control border-muted"
                        placeholder="Enter Registered Address"
                        name="registerAddress" defaultValue={formData.registerAddress} onChange={handleFormDataChange}
                      /> */}
														<input
															placeholder="Enter Your registerAddress"
															{...formik.getFieldProps('registerAddress')}
															className={clsx('form-control border-muted')}
															type="text"
															name="registerAddress"
														/>
													</div>

													<div className="col-12 mt-3">
														<button
															type="button"
															className="btn btn-primary"
															onClick={() => {
																handlegstSubmit();
															}}
															data-bs-toggle="collapse"
															data-bs-target="#collapsePayment"
															aria-expanded="true"
															aria-controls="collapsePayment">
															Continue
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div
										className={`accordion-item accordion-item ${
											showPayment ? 'show' : ''
										} border-1 border-dark mb-2 border`}>
										<h2 className="accordion-header font-subtitle bg-light" id="headingPayment">
											<button
												className="accordion-button font-subtitle bg-light d-flex align-items-center justify-content-between"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#collapsePayment"
												aria-expanded="true"
												aria-controls="collapsePayment">
												<div className="a-titles">
													<PiNumberCircleFourFill className="checkout-number fs-5 me-2 mb-1" />
													<span>Payment</span>
												</div>
											</button>
										</h2>
										{total.totalPrice > 0 && (
											<div
												id="collapsePayment"
												className={`accordion-collapse collapse`}
												aria-labelledby="headingPayment"
												data-bs-parent="#accordionExample">
												<div className="accordion-body py-2">
													<button
														type="submit"
														className="font-subtitle btn btn-primary fs-6 py-1 px-3">
														Proceed to Pay <i className="fa fa-indian-rupee fs-6"></i>
														<span className="fs-6 fw-bold">{total.totalPrice}</span>
													</button>
												</div>
											</div>
										)}
									</div>
								</>
							</form>
						</div>

						{/*<Login*/}
						{/*	open={open}*/}
						{/*	setOpen={setOpen}*/}
						{/*	toggleLoginOpen={toggleLoginOpen}*/}
						{/*	toggleRegisterOpen={toggleRegisterOpen}*/}
						{/*	openRegister={openRegister}*/}
						{/*	setOpenRegister={setOpenRegister}*/}
						{/*/>*/}

						{/*<Register*/}
						{/*	openRegister={openRegister}*/}
						{/*	handleRegisterClose={handleRegisterClose}*/}
						{/*	setOpenRegister={setOpenRegister}*/}
						{/*	toggleRegisterOpen={toggleRegisterOpen}*/}
						{/*	toggleLoginOpen={toggleLoginOpen}*/}
						{/*/>*/}
					</section>
				</>
			)}
		</>
	);
};
