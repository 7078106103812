import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { Course, CourseCostType, Mentor } from 'components/_v2/Course/type';
import { RUPEE_SYMBOL } from 'utils/consts';
import React, { useMemo } from 'react';
import { useUserContext } from 'context/UserContext';
import StateAndCountryDropDowns from 'components/_v2/PaymentDetails/StateAndCountryDropdowns';
import { useUserAddressContext } from 'context/UserAddressContext';

const PaymentBorder = <div className="payment-border" />;

interface MoneyDistributionProps {
	heading: string;
	amount: number | string;
	classes: string;
	hideRupeeSymbol?: boolean;
}

const MoneyDistribution: React.FC<MoneyDistributionProps> = ({
	heading,
	amount,
	classes,
	hideRupeeSymbol,
}) => (
	<div className={`d-flex justify-content-between g_reg ${classes}`}>
		<div>{heading}:</div>
		<div>
			{hideRupeeSymbol ? '' : RUPEE_SYMBOL}
			{typeof amount === 'number' ? amount.toFixed(2) : amount}
		</div>
	</div>
);

const CourseMoneyBreakdown = ({ course }: { course: Course }) => {
	const { user } = useUserContext();
	const { setSelectedStateId, setSelectedCountryId } = useUserAddressContext();
	const totalDiscount = useMemo(() => (course?.amount ?? 0) - (course?.sellPrice ?? 0), [course]);

	const gstOverSellPrice = useMemo(() => {
		const gst = ((course?.sellPrice ?? 0) / 100) * 18;
		return parseFloat(gst.toFixed(2));
	}, [course]);

	const isCoursePaidType = course?.costType === CourseCostType.PAID;
	const totalPayable = useMemo(
		() => (isCoursePaidType ? gstOverSellPrice + (course?.sellPrice ?? 0) : 'Free'),
		[course],
	);

	const handleCountryChange = (newCountryId: string) => {
		setSelectedCountryId(newCountryId);
	};

	const handleStateChange = (newStateId: string) => {
		setSelectedStateId(newStateId);
	};

	return (
		<>
			<div>
				<div className="d-flex align-items-center">
					<div>
						<img
							src={toAbsoluteUrl('/media/payment-details/payment-img.png')}
							alt="Course_Image"
							className="img-fluid"
						/>
					</div>
					<div className="ms-3">
						<div className="payment-course-name g_mid">{course?.name}</div>
						<div className="payment-course-label g_reg">
							by{' '}
							{(course?.mentors || []).map((mentor: { mentor: Mentor }, index: number) => {
								const mentorName = mentor.mentor.fullName;
								return mentorName + ((course?.mentors?.length || 1) - 1 != index ? ', ' : '');
							})}
						</div>
					</div>
				</div>

				{user && (
					<div className="pt-3">
						<StateAndCountryDropDowns
							handleCountryChange={handleCountryChange}
							handleStateChange={handleStateChange}
						/>
					</div>
				)}

				<p className="summary-heading g_reg">Order Summary:</p>
				{PaymentBorder}
				{isCoursePaidType ? (
					<>
						{MoneyDistribution({
							amount: course?.amount ?? 0,
							classes: 'amount-label',
							heading: 'Course Amount',
						})}

						{MoneyDistribution({
							amount: totalDiscount,
							classes: 'amount-label',
							heading: 'Discount',
						})}

						{MoneyDistribution({
							amount: totalPayable ?? 0,
							classes: 'subtotal-label',
							heading: 'Subtotal',
						})}
						{PaymentBorder}
					</>
				) : (
					<></>
				)}
				{MoneyDistribution({
					amount: totalPayable,
					classes: 'total-price-label',
					heading: 'Total Payable',
					hideRupeeSymbol: !isCoursePaidType,
				})}
				<div className="d-flex justify-content-between py-2">
					{isCoursePaidType && (
						<>
							<div />
							<div className="color17 fs-14">
								<span>*Includes Taxes</span>
							</div>
						</>
					)}
				</div>
			</div>
		</>
	);
};

export default CourseMoneyBreakdown;
