import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { LessonStatusEnum, LessonType, LessonTypeEnum } from 'components/_v2/Course/type';
import { useQuizContext } from 'context/QuizContext';
import { Button, Image } from 'react-bootstrap';
import moment from 'moment';
import useQuiz from 'hooks/useQuiz';
import { useNavigate } from 'react-router-dom';
import { ZOOM_MEETING } from 'pages/routing/routePages';
import useIsSmallScreen from '../../../../hooks/useIsSmallScreen';
import { useCourseContext } from '../../../../context/CourseContext';

const ButtonRenderer = ({
	bgColor,
	content,
	onClickAction,
	disabled,
}: {
	bgColor: string;
	content: JSX.Element | string;
	onClickAction: () => void;
	disabled?: boolean;
}) => {
	return (
		<Button
			style={{
				backgroundColor: bgColor,
				borderRadius: '12px',
				borderColor: 'transparent',
				lineHeight: '21px',
				fontSize: '14px',
			}}
			disabled={disabled}
			className="d-flex align-items-center gap-2"
			onClick={onClickAction}>
			{content}
		</Button>
	);
};

const LessonTypeRenderer = ({
	lessonData,
	courseId,
}: {
	lessonData: LessonType;
	courseId: string;
}) => {
	const { setLessonId, setRemainingTime, setAllQuestionAnswers, setEndTime, setQuizResult } =
		useQuizContext();
	const navigate = useNavigate();
	const { userCourses } = useCourseContext();
	const { handleQuizSubmit } = useQuiz();
	const isMobileScreen = useIsSmallScreen(576);

	if (lessonData.isLock) {
		return null;
	}

	const handleDownloadFile = (downloadContentUrl: string) => {
		const link = document.createElement('a');
		link.href = downloadContentUrl;
		const courseName = userCourses.find((course) => course.id === courseId)?.name;
		const fileName = courseName + ':' + lessonData.name;
		link.download = fileName;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	const handleQuizStart = (lessonId: string) => {
		setLessonId(lessonId);
		handleQuizSubmit({
			courseId,
			isSubmit: false,
			lessonId: lessonId,
			questionAnswer: [],
			quizId: lessonData.quizId,
			sectionId: lessonData.sectionId,
			callbackFunction: (response) => {
				const endTime = response.submitQuiz.endTime;
				const remainingTime = moment(endTime).diff(moment(), 'seconds');
				setEndTime(endTime);
				setRemainingTime(remainingTime);
				setAllQuestionAnswers(response.submitQuiz.questionAnswer || []);
				setQuizResult((prevState) => {
					return { ...prevState, quizStartTime: moment().toISOString() };
				});
			},
		});
	};

	const commonClasses = 'd-flex flex-column align-items-center gap-4 pb-2 pb-md-0';

	switch (lessonData.type) {
		case LessonTypeEnum.DOCS:
			return (
				<div className={commonClasses}>
					<Image
						src={toAbsoluteUrl('/media/course-detail-images/file-download.svg')}
						width={128}
						height={128}
					/>
					<ButtonRenderer
						bgColor="#3CC878"
						content={
							<>
								<Image src={toAbsoluteUrl('/media/course-detail-images/download-icon.svg')} />
								Download course sheet pdf
							</>
						}
						disabled={!lessonData.contentUrl}
						onClickAction={() => handleDownloadFile(lessonData.contentUrl)}
					/>
				</div>
			);
		case LessonTypeEnum.QUIZ:
			return (
				<div className={commonClasses}>
					<Image src={toAbsoluteUrl('/media/course-detail-images/quiz.svg')} />
					<button
						className={`quiz-start-btn-style g_mid border-1`}
						onClick={() => handleQuizStart(lessonData.id)}>
						Start Quiz
					</button>
				</div>
			);

		case LessonTypeEnum.MEETING:
			const meetingCompleted = lessonData.status === LessonStatusEnum.COMPLETED;
			return (
				<div className={commonClasses}>
					<Image src={toAbsoluteUrl('/media/course-detail-images/zoom-workplace.svg')} />
					<Button
						onClick={() => {
							!isMobileScreen
								? navigate(`${ZOOM_MEETING}?lessonId=${lessonData.id}&courseId=${courseId}`)
								: window.open(lessonData.meetingUrl);
						}}
						disabled={meetingCompleted}>
						{meetingCompleted ? 'Meeting Completed' : 'Join Meeting'}
					</Button>
				</div>
			);

		default:
			break;
	}

	return <></>;
};

export default LessonTypeRenderer;
