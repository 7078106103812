import { ZoomMtg } from '@zoom/meetingsdk';
import { gql, useQuery } from '@apollo/client';
import {
	ZoomMeetingApiResponse,
	ZoomMeetingDetails,
} from 'components/_v2/ViewCourseData/LessonTypesComponents/type';
import { getViewCourseWithSearchSlug, MY_COURSE_URL } from 'pages/routing/routePages';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useCourseContext } from 'context/CourseContext';
import { useZoomMeeting } from 'context/ZoomMeetingContext';
import Loading from 'components/_v2/CommonComponents/Loading';
import { removeChatStickyFromUi } from 'utils/helpers';

const GET_ZOOM_MEETING_DETAILS = gql`
	query GetZoomMeetingDetails($lessonID: String!) {
		getZoomMeetingDetails(lessonID: $lessonID) {
			username
			email
			signature
			sdkKey
			meetingNumber
			password
		}
	}
`;

const ZoomMeeting = () => {
	useLayoutEffect(() => {
		ZoomMtg.preLoadWasm();
		ZoomMtg.prepareWebSDK();
	}, []);

	const navigate = useNavigate();
	const location = useLocation();
	const { setIsZoomVisited } = useZoomMeeting();
	const { search } = location;
	const lessonID: string = new URLSearchParams(search).get('lessonId');
	const courseId: string = new URLSearchParams(search).get('courseId');

	const { userCourses } = useCourseContext();

	const [zoomDetails, setZoomDetails] = useState<ZoomMeetingDetails>();

	const { loading, error } = useQuery<ZoomMeetingApiResponse>(GET_ZOOM_MEETING_DETAILS, {
		variables: { lessonID },
		onCompleted: (data: ZoomMeetingApiResponse) => {
			setZoomDetails(data.getZoomMeetingDetails);
			setIsZoomVisited(true);
		},
	});

	function startMeeting() {
		document.getElementById('zmmtg-root').style.display = 'block';

		const baseURL = window.location.origin;
		const courseSlug = userCourses.find((course) => course.id === courseId)?.slug;
		const leaveUrl = `${baseURL}${getViewCourseWithSearchSlug(courseSlug)}`;
		// const leaveUrl = `${baseURL}${MY_COURSE_URL}`;

		ZoomMtg.init({
			leaveUrl,
			patchJsMedia: true,
			meetingInfo: [],
			leaveOnPageUnload: true,
			success: () => {
				removeChatStickyFromUi();
				ZoomMtg.join({
					signature: zoomDetails.signature,
					sdkKey: zoomDetails.sdkKey,
					meetingNumber: zoomDetails.meetingNumber,
					passWord: zoomDetails.password,
					userName: zoomDetails.username,
					userEmail: zoomDetails.email,
					// tk: registrantToken,
					// zak: zakToken,
					success: (success) => {
						ZoomMtg.getAttendeeslist({});
						ZoomMtg.getCurrentUser({
							success: function (res) {
								console.log('success getCurrentUser', res.result.currentUser);
							},
						});
					},
					error: (error) => {
						console.log(error);
						toast.error(error.message);
						window.open(MY_COURSE_URL);
					},
				});
			},
			error: (error) => {
				console.log(error);
				toast.error(error.message);
				window.open(MY_COURSE_URL);
			},
		});
	}

	useEffect(() => {
		const element = document.getElementById('zmmtg-root');

		if (element) {
			element.style.display = 'none';
		}

		if (zoomDetails) {
			startMeeting();
		}
	}, [zoomDetails]);

	if (loading) {
		return <Loading width={250} height="100vh" />;
	}

	if (error) {
		toast.error(error.message);
		window.open(MY_COURSE_URL);
	}

	return <></>;
	//
	// return (
	// 	<div className="test" style={{ width: 300, height: 300 }}>
	// 		<main>
	// 			<h1>Zoom Meeting SDK Sample React</h1>
	//
	// 			<button onClick={startMeeting}>Join Meeting</button>
	// 		</main>
	// 	</div>
	// );
};

export default ZoomMeeting;
