import {
	QuestionAnswerInputType,
	QuizSubmitType,
} from 'components/_v2/ViewCourseData/LessonTypesComponents/Quiz/type';
import useQuiz from 'hooks/useQuiz';
import moment from 'moment';
import React, { createContext, useState, useContext, useMemo, useCallback, useEffect } from 'react';

type NewQuestionDetailsType = Omit<QuizSubmitType, 'questionAnswer' | 'lessonId'>;

interface QuizResult {
	quizStartTime: string;
	quizEndTime: string;
	quizSubmitTime: string;
	questionAnswer: QuestionAnswerInputType[];
	isSubmit: boolean;
}
interface QuizContextType {
	quiz: {
		lessonId: string;
		answersProvided: QuestionAnswerInputType[];
		currentQuestion: number;
		remainingTime: number;
		endTime: string;
		isRetake: boolean;
	};
	quizResult: QuizResult;
	currentSelectedLessonId: string;
	currentSelectedSectionId: string;
	setQuizResult: React.Dispatch<React.SetStateAction<QuizResult>>;
	setLessonId: (lessonId: string) => void;
	setEndTime: (endTime: string) => void;
	setRemainingTime: (time: number) => void;
	setCurrentQuestion: (
		newQuestionNumber: number,
		newQuestionDetails?: NewQuestionDetailsType,
	) => void;
	setAnswer: (questionId: string, answer: string[]) => void;
	setAllQuestionAnswers: (questionAnswers: QuestionAnswerInputType[]) => void;
	resetQuizState: () => void;
	resetQuizResultState: () => void;
	setCurrentSelectedSectionId: React.Dispatch<React.SetStateAction<string>>;
	setCurrentSelectedLessonId: React.Dispatch<React.SetStateAction<string>>;
	loadingGetQuizReattempt: boolean;
	setLoadingGetQuizReattempt: React.Dispatch<React.SetStateAction<boolean>>;
	loadingGetQuizResult: boolean;
	setLoadingGetQuizResult: React.Dispatch<React.SetStateAction<boolean>>;
	setRetakeQuiz: React.Dispatch<React.SetStateAction<boolean>>;
}

const quizLocalStorageKey = 'quizDetails';

const initialState: {
	lessonId: string;
	answersProvided: QuestionAnswerInputType[];
	currentQuestion: number;
	remainingTime: number;
	endTime: string;
	isRetake: boolean;
} = {
	lessonId: '',
	answersProvided: [],
	currentQuestion: 1,
	remainingTime: 0,
	endTime: '',
	isRetake: false,
};

const initialQuizResultState: {
	quizStartTime: string;
	quizEndTime: string;
	quizSubmitTime: string;
	questionAnswer: QuestionAnswerInputType[];
	isSubmit: boolean;
} = {
	quizStartTime: '',
	quizEndTime: '',
	quizSubmitTime: '',
	questionAnswer: [],
	isSubmit: false,
};

const QuizContext = createContext<QuizContextType>({
	quiz: initialState,
	quizResult: initialQuizResultState,
	currentSelectedLessonId: '',
	currentSelectedSectionId: '',
	setLessonId: (lessonId: string) => {},
	setRemainingTime: (time: number) => {},
	setAnswer: (questionId: string, answer: string[]) => {},
	setAllQuestionAnswers: (questionAnswers: QuestionAnswerInputType[]) => {},
	setEndTime: () => {},
	setQuizResult: (quizResult: QuizResult) => {},
	setCurrentQuestion: (
		newQuestionNumber: number,
		newQuestionDetails?: NewQuestionDetailsType,
	) => {},
	resetQuizState: () => {},
	resetQuizResultState: () => {},
	setCurrentSelectedSectionId: (value: string) => {},
	setCurrentSelectedLessonId: (value: string) => {},
	loadingGetQuizReattempt: false,
	setLoadingGetQuizReattempt: (value: boolean) => {},
	loadingGetQuizResult: false,
	setLoadingGetQuizResult: (value: boolean) => {},
	setRetakeQuiz: (value: boolean) => {},
});

export const QuizProvider = ({ children }: { children: React.ReactNode }) => {
	const [quiz, setQuiz] = useState(initialState);
	const [quizResult, setQuizResult] = useState(initialQuizResultState);
	const [currentSelectedLessonId, setCurrentSelectedLessonId] = useState<string>('');
	const [currentSelectedSectionId, setCurrentSelectedSectionId] = useState<string>('');
	const [loadingGetQuizReattempt, setLoadingGetQuizReattempt] = useState(false);
	const [loadingGetQuizResult, setLoadingGetQuizResult] = useState(false);

	const { handleQuizSubmit, handleGetQuizResult } = useQuiz();

	useEffect(() => {
		const localStorageDetails = JSON.parse(localStorage.getItem(quizLocalStorageKey));
		if (localStorageDetails) {
			const allExistingLessonIds = Object.keys(localStorageDetails);
			const lessonIdWithActiveQuiz = allExistingLessonIds.find((lessonId: string) =>
				moment(localStorageDetails[lessonId].endTime).isSameOrAfter(moment()),
			);

			if (lessonIdWithActiveQuiz) {
				setLessonId(lessonIdWithActiveQuiz);
				handleGetQuizResult({
					courseId: localStorageDetails[lessonIdWithActiveQuiz].courseId,
					lessonId: lessonIdWithActiveQuiz,
					quizId: localStorageDetails[lessonIdWithActiveQuiz].quizId,
					sectionId: localStorageDetails[lessonIdWithActiveQuiz].sectionId,
				});
			}
		}
	}, []);

	const setLessonId = useCallback(
		(lessonId: string) => {
			setQuiz((prevQuiz) => ({ ...prevQuiz, lessonId }));
		},
		[quiz],
	);

	const setCurrentQuestion = useCallback(
		(newQuestionNumber: number, newQuestionDetails?: NewQuestionDetailsType) => {
			setQuiz((prevQuiz) => ({
				...prevQuiz,
				currentQuestion: newQuestionNumber,
			}));
			if (newQuestionDetails) {
				handleQuizSubmit({
					courseId: newQuestionDetails.courseId,
					questionAnswer: quiz.answersProvided || [],
					isSubmit: newQuestionDetails.isSubmit,
					lessonId: quiz.lessonId,
					quizId: newQuestionDetails.quizId,
					sectionId: newQuestionDetails.sectionId,
					is_retake: newQuestionDetails.is_retake,
					callbackFunction: (data) => {
						handleGetQuizResult({
							courseId: newQuestionDetails.courseId,
							lessonId: quiz.lessonId,
							quizId: newQuestionDetails.quizId,
							sectionId: newQuestionDetails.sectionId,
						});
						setQuizResult((prevState) => ({
							...prevState,
							isSubmit: data?.submitQuiz?.isSubmit,
						}));
					},
				});
			}
		},
		[quiz],
	);

	const setAnswer = useCallback(
		(questionId: string, answer: string[]) => {
			setQuiz((prevQuiz) => {
				const updatedQuizState = { ...prevQuiz };
				const answerAlreadyExistsIndex = (prevQuiz.answersProvided || []).findIndex(
					(question) => question.questionId == questionId,
				);
				if (answerAlreadyExistsIndex >= 0) {
					updatedQuizState.answersProvided[answerAlreadyExistsIndex].answer = answer;
				} else {
					updatedQuizState['answersProvided'].push({ questionId, answer });
				}
				return updatedQuizState;
			});
		},
		[quiz],
	);

	const setAllQuestionAnswers = useCallback(
		(questionAnswers: QuestionAnswerInputType[]) => {
			setQuiz((prevQuiz) => ({
				...prevQuiz,
				answersProvided: questionAnswers,
			}));
		},
		[quiz],
	);

	const setRemainingTime = useCallback(
		(remainingTime: number) => {
			setQuiz((prevQuiz) => ({ ...prevQuiz, remainingTime }));
		},
		[quiz],
	);

	const resetQuizState = useCallback(() => {
		setQuiz(initialState);
	}, []);

	const setEndTime = useCallback(
		(endTime: string) => {
			setQuiz((prevQuiz) => ({ ...prevQuiz, endTime }));
		},
		[quiz],
	);

	const resetQuizResultState = () => {
		setQuizResult(initialQuizResultState);
	};

	const setRetakeQuiz = useCallback(
		(value: boolean) => {
			setQuiz((prevQuiz) => ({ ...prevQuiz, isRetake: value }));
		},
		[quiz],
	);

	const value = useMemo(
		() => ({
			quiz,
			quizResult,
			setQuizResult,
			setLessonId,
			setAnswer,
			resetQuizState,
			setRemainingTime,
			setEndTime,
			setCurrentQuestion,
			setAllQuestionAnswers,
			resetQuizResultState,
			currentSelectedLessonId,
			currentSelectedSectionId,
			setCurrentSelectedLessonId,
			setCurrentSelectedSectionId,
			loadingGetQuizReattempt,
			setLoadingGetQuizReattempt,
			loadingGetQuizResult,
			setLoadingGetQuizResult,
			setRetakeQuiz,
		}),
		[
			quiz,
			setLessonId,
			setAnswer,
			resetQuizState,
			setRemainingTime,
			setEndTime,
			setCurrentQuestion,
			setAllQuestionAnswers,
			quizResult,
			setQuizResult,
			resetQuizResultState,
			currentSelectedLessonId,
			currentSelectedSectionId,
			setCurrentSelectedLessonId,
			setCurrentSelectedSectionId,
			loadingGetQuizReattempt,
			setLoadingGetQuizReattempt,
			loadingGetQuizResult,
			setLoadingGetQuizResult,
			setRetakeQuiz,
		],
	);

	return <QuizContext.Provider value={value}>{children}</QuizContext.Provider>;
};

export const useQuizContext = () => {
	return useContext(QuizContext);
};
