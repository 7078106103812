// AuthModalContext.tsx
import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';

// Define the type for the context value
interface ModalContextType {
	open: boolean;
	verification: boolean;
	forgotPassword: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	toggleLoginModal: () => void;
	openRegister: boolean;
	setOpenRegister: React.Dispatch<React.SetStateAction<boolean>>;
	toggleRegisterModal: () => void;
	toggleVerificationModal: () => void;
	toggleForgotModal: () => void;
}

// Create a context with an initial value of undefined
const AuthModalContext = createContext<ModalContextType | undefined>(undefined);

// Create a provider component
export const AuthModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [open, setOpen] = useState<boolean>(false);
	const [openRegister, setOpenRegister] = useState<boolean>(false);
	const [verification, setVerification] = useState<boolean>(false);
	const [forgotPassword, setForgotPassword] = useState<boolean>(false);

	const toggleLoginModal = () => setOpen((prev) => !prev);
	const toggleVerificationModal = () => setVerification((prev) => !prev);
	const toggleRegisterModal = () => setOpenRegister((prev) => !prev);
	const toggleForgotModal = () => setForgotPassword((prev) => !prev);

	return (
		<AuthModalContext.Provider
			value={{
				open,
				setOpen,
				toggleLoginModal,
				openRegister,
				toggleVerificationModal,
				setOpenRegister,
				toggleRegisterModal,
				verification,
				forgotPassword,
				toggleForgotModal,
			}}>
			{children}
		</AuthModalContext.Provider>
	);
};

// Custom hook to use the modal context
export const useAuthModalContext = (): ModalContextType => {
	const context = useContext(AuthModalContext);
	if (!context) {
		throw new Error('useModalContext must be used within a ModalProvider');
	}
	return context;
};
